import { styled, useMediaQuery } from "@mui/material";
import type { Theme } from "@mui/material/styles/createTheme";
import { useMobileNav } from "hooks/use-mobile-nav";
import { useSections } from "./Config";
import Footer from "./components/Footer";
import MobileNav from "./components/MobileNav";
import TopNav from "./components/TopNav";

const SIDE_NAV_WIDTH = 280;

const VerticalLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const VerticalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
  position: "absolute",
  top: 0,
});

interface CustomProps {
  children: any;
}

const DrawerLayout = (props: CustomProps) => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const mobileNav = useMobileNav();
  const sections = useSections();

  return (
    <>
      <TopNav onMobileNavOpen={mobileNav.handleOpen} />
      {!mdUp && (
        <MobileNav
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <VerticalLayoutContainer>
        {props.children} <Footer />
      </VerticalLayoutContainer>
    </>
  );
};

export default DrawerLayout;
