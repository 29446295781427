import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import {
  Box,
  Container,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import CustomButton from "global/components/CustomButton/CustomButton";
import viewpaths from "global/constants/ViewPathConstants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AnimatedSection from "screens/Shared/AnimationSection";
import history from "utils/history";
import { LightTooltip, theme } from "utils/styles";
import rightArrow from "../../../assets/icons/rightArrow.svg";
import content1 from "../../../assets/images/content1.jpg";
import healthCalculator from "../../../assets/images/healthCalculator.webp";
import careerCalculator from "../../../assets/images/img2.jpg";
import content3 from "../../../assets/images/img5.jpeg";
import servicesImage from "../../../assets/images/servicesImage.webp";
import sportsCalculator from "../../../assets/images/sportsCalculator.jpg";
import homeStyles from "../Home.styles";

const OurServicesSection = ({
  section3Ref,
}: {
  section3Ref: React.RefObject<HTMLElement>;
}) => {
  const classes = homeStyles;
  const { t } = useTranslation();
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const [selectedItem, setSelectedItem] = useState<string>("sports");
  const [scrollY, setScrollY] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const items = [
    {
      key: "sports",
      label: t("sports"),
      description: t("sportsDesc"),
      image: sportsCalculator,
      url: viewpaths.footballPredictionViewPath,
    },
    {
      key: "career",
      label: t("career"),
      description: t("careerDesc"),
      image: careerCalculator,
      url: "",
    },
    {
      key: "health",
      label: t("health"),
      description: t("healthDesc"),
      image: healthCalculator,
      url: "",
    },
  ];
  const selectedItemData = items.find((item) => item.key === selectedItem);

  useEffect(() => {
    if (selectedItemData) {
      const img = new Image();
      img.src = selectedItemData.image;
      img.onload = () => {
        setImageLoaded(true);
      };
    }
  }, [selectedItemData]);

  return (
    <Box py={15} ref={section3Ref}>
      <Container maxWidth="md">
        <Stack
          direction={"column"}
          spacing={5}
          textAlign={"center"}
          alignItems={"center"}
        >
          <Typography sx={classes.gradientText}>{t("ourServices")}</Typography>
          <AnimatedSection animationType="fadeInUp">
            <Typography sx={classes.contentHeaderText}>
              {t("ourServicesHeader")}
            </Typography>
          </AnimatedSection>
          <AnimatedSection animationType="fadeInUp">
            <Typography sx={classes.subHeaderText}>
              {t("ourServicesSubHeader")}
            </Typography>
          </AnimatedSection>
        </Stack>
      </Container>
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid container spacing={10}>
          <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
            <Stack
              direction={"column"}
              spacing={0}
              sx={{ borderLeft: "1px solid #767676" }}
            >
              {items.map((item) => (
                <AnimatedSection animationType="fadeInDown">
                  <Stack
                    key={item.key}
                    direction={"column"}
                    spacing={1}
                    onClick={() => setSelectedItem(item.key)}
                    sx={
                      selectedItem === item.key
                        ? [
                            classes.calculatorWrapper,
                            classes.selectedCalculatorWrapper,
                          ]
                        : classes.calculatorWrapper
                    }
                  >
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography
                        sx={
                          selectedItem === item.key
                            ? {
                                ...classes.gradientText,
                                fontSize: "20px",
                                [theme.breakpoints.down("md")]: {
                                  fontSize: "19px",
                                },
                              }
                            : classes.calculatorHeader
                        }
                      >
                        {item.label}
                      </Typography>
                      {selectedItem === item.key && (
                        <LightTooltip
                          title={t("tryNow")}
                          placement="top"
                          arrow
                          sx={{
                            "& .MuiTooltip-arrow": {
                              color: "#1b202a",
                            },
                          }}
                        >
                          <Box
                            component="img"
                            src={rightArrow}
                            alt={"icon"}
                            sx={{
                              height: "20px",
                              background:
                                "linear-gradient(90deg, #ff68cc29 0%, #dc5fff21 33%, #a080ff2e 67%, #7f99ff70 100%) !important",
                              borderRadius: "50%",
                              padding: "10px",
                              [theme.breakpoints.down("md")]: {
                                height: "15px",
                                padding: "7px",
                              },
                            }}
                            onClick={() => history.push(item.url)}
                          />
                        </LightTooltip>
                      )}
                    </Stack>
                    <Typography sx={classes.subHeaderText}>
                      {item.description}
                    </Typography>
                  </Stack>
                </AnimatedSection>
              ))}
            </Stack>
          </Grid>
          <Grid
            item
            xl={7}
            lg={7}
            md={7}
            sm={12}
            xs={12}
            alignContent={"center"}
            textAlign={"center"}
          >
            <AnimatePresence mode="wait">
              {selectedItemData && imageLoaded ? (
                <motion.div
                  key={selectedItemData.image}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 0.6,
                      type: "spring",
                      stiffness: 300,
                      damping: 20,
                    },
                  }}
                  exit={{
                    opacity: 0,
                    y: 50,
                    transition: { duration: 0.4 },
                  }}
                >
                  <Box
                    component="img"
                    src={selectedItemData.image}
                    alt={selectedItemData.label}
                    sx={classes.calculatorsImage}
                  />
                </motion.div>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "600px",
                    borderRadius: "10px",
                    backgroundColor: "#f0f0f0", // Placeholder color
                  }}
                >
                  {/* Optionally, add a loading spinner or a skeleton here */}
                </Box>
              )}
            </AnimatePresence>
          </Grid>
        </Grid>
      </Container>
      <Box sx={classes.serviceWrapper}>
        <Box sx={classes.greyBox}>
          {smUp && (
            <Box width={"100%"} height={"30px"}>
              <Box
                component="img"
                src={content1}
                alt={"icon"}
                sx={{
                  ...classes.serviceImage1,
                  transform: `translateY(${
                    scrollY * -0.1 + (mdUp ? 300 : 450)
                  }px)`,
                }}
              />
            </Box>
          )}
          <Container maxWidth="xs">
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={{ md: 5, xs: 2 }}
              py={{ md: 0, xs: 2 }}
            >
              <Box component="img" src={servicesImage} alt={"icon"} />
              <Typography
                sx={{
                  ...classes.contentHeaderText,
                  fontSize: "22px",
                  lineHeight: 1.8,
                  [theme.breakpoints.down("md")]: {
                    fontSize: "18px",
                    lineHeight: 1.5,
                  },
                }}
              >
                {t("contactForService")}
              </Typography>
              <CustomButton
                label={t("contactUs")}
                buttonType="gradientBtn"
                onClick={() => history.push(viewpaths.contactViewPath)}
                customClasses={classes.ourServiceBtn}
                endIcon={<EastOutlinedIcon />}
              />
            </Stack>
          </Container>
          {smUp && (
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"end"}
              height={"30px"}
            >
              <Box
                component="img"
                src={content3}
                alt={"icon"}
                sx={{
                  ...classes.serviceImage2,
                  transform: `translateY(${scrollY * 0.1 - 550}px)`,
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default OurServicesSection;
