import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { createStyles, createTheme, styled } from "@mui/material/styles";

const primaryColor = "#FFFFFF";
const primaryBackgroundColor = "#EDEDED";
const borderColor = "rgba(0, 0, 0, 0.12)";
const borderStyle = "1px solid " + borderColor;
const infoTextColor = "#888888";
const defaultFontSize = 14;
const defaultBoxShadow = "0px 2px 10px 0px rgba(0, 0, 0, 0.04)";
const drawerWidth = 300;
const primaryColorWhite = "#FFFFFF";
const primaryBorderColor = "#E7E7E7";
const primaryLightPurple = "#8884D8";
const primaryColorRed = "#CF0000";
const primaryColorPurple = "#6842EF";
const primaryColorBlue = "#2E3152";
const primaryColorYellow = "#F8B179";
const primaryColorBlack = "#000";
const primaryActiveTabBgColor = "#F0ECFF";
const primaryInactiveTabBgColor = "#F9F9F9";
const blueAsteriskColor = "#24CBC7";
const borderRadius = "8px";
const inputLabelRequiredColor = "red";
const blackFont = {
  fontFamily: "Poppins",
  fontWeight: 900,
  fontStyle: "normal",
};

const boldFont = {
  fontFamily: "Poppins",
  fontWeight: 600,
};

const mediumFont = {
  fontFamily: "Poppins",
  fontWeight: 500,
};

const regularFont = {
  fontFamily: "Poppins",
  fontWeight: 400,
};

const theme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "TT_Norms_Pro_Regular",
      "TT_Norms_Pro_Thin",
      "TT_Norms_Pro_Bold",
      "TT_Norms_Pro_Light",
      "TT_Norms_Pro_Medium",
      "sans-serif",
    ].join(","),
  },
  // overrides: {
  //   MuiCssBaseline: {
  //     "@global": {
  //       "@font-face": [raleway],
  //     },
  //   },
  // },
});

const getRelativeFontSize = (value: number = 0) => {
  let size = defaultFontSize + value;
  return size + "px";
};

const customButtonStyle = {
  borderRadius: borderRadius,
  border: "none",
  fontSize: getRelativeFontSize(),
  textAlign: "center",
  padding: "10px 15px",
  color: "white",
  cursor: "pointer",
  textTransform: "none",
  // boxShadow: defaultBoxShadow,
};
const customBorderButtonStyle = {
  background: "#FFFFFF",
  border: "1px solid #1E1E1E !important",
  color: "#1E1E1E",
  "&:hover": {
    background: "#FFFFFF",
    border: "1px solid #1E1E1E",
    color: "#1E1E1E",
  },
};

const customTextFieldStyle = {
  borderRadius: borderRadius,
  position: "relative",
  border: "none",
  fontSize: getRelativeFontSize(2),
  backgroundColor: primaryBackgroundColor,
  padding: "10px 15px",
  boxShadow: "none",
  width: "100%",
};

const headingText = {
  display: "inline-block",
  fontSize: getRelativeFontSize(8),
};

const centerItemFlex = {
  display: "flex",
  alignItems: "baseLine",
  justifyContent: "center",
};

const centerItemAbsolute = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

export {
  blackFont,
  blueAsteriskColor,
  boldFont,
  borderColor,
  borderRadius,
  borderStyle,
  centerItemAbsolute,
  centerItemFlex,
  customBorderButtonStyle,
  customButtonStyle,
  customTextFieldStyle,
  defaultBoxShadow,
  drawerWidth,
  getRelativeFontSize,
  headingText,
  infoTextColor,
  inputLabelRequiredColor,
  mediumFont,
  primaryActiveTabBgColor,
  primaryBackgroundColor,
  primaryBorderColor,
  primaryColor,
  primaryColorBlack,
  primaryColorBlue,
  primaryColorPurple,
  primaryColorRed,
  primaryColorWhite,
  primaryColorYellow,
  primaryInactiveTabBgColor,
  primaryLightPurple,
  regularFont,
  theme,
};

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1b202a",
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    ...regularFont,
  },
}));

export const animationStyle = createStyles({
  fadeInDown: {
    animation: `slideUp 2s ease-out forwards`,
    opacity: 0,
    animationDelay: "0.5s",
  },
  zoomOut: {
    animation: `zoomOutEffect 2s ease-out forwards`,
    opacity: 0,
    animationDelay: "0.5s",
  },
  zoomIn: {
    animation: `zoomInEffect 2s ease-out forwards`,
    opacity: 0,
    animationDelay: "0.5s",
  },
  fadeInRight: {
    animation: `$fadeInRight 2s ease-out forwards`,
    opacity: 0,
    transform: "translateX(50px)",
  },
  fadeInLeft: {
    animation: `$fadeInLeft 2s ease-out forwards`,
    opacity: 0,
    transform: "translateX(-50px)",
  },
  fadeInLeftDelay: {
    animationDelay: "1s", // adds a 0.5 second delay for the second Grid item
  },
  "@global": {
    "@keyframes slideUp": {
      "0%": {
        transform: "translateY(50px)",
        opacity: 0,
      },
      "100%": {
        transform: "translateY(0px)",
        opacity: 1,
      },
    },
    "@keyframes zoomOutEffect": {
      "0%": {
        transform: "scale(1.2)",
        opacity: 0,
      },
      "100%": {
        transform: "scale(1)",
        opacity: 1,
      },
    },
    "@keyframes zoomInEffect": {
      "0%": {
        transform: "scale(0.5)",
        opacity: 0,
      },
      "100%": {
        transform: "scale(1)",
        opacity: 1,
      },
    },
    "@keyframes fadeInRight": {
      "0%": {
        opacity: 0,
        transform: "translateX(50px)",
      },
      "100%": {
        opacity: 1,
        transform: "translateX(0)",
      },
    },
    "@keyframes fadeInLeft": {
      "0%": {
        opacity: 0,
        transform: "translateX(-50px)",
      },
      "100%": {
        opacity: 1,
        transform: "translateX(0)",
      },
    },
  },
});
