import {
  getRelativeFontSize,
  inputLabelRequiredColor,
  mediumFont,
  primaryColorWhite,
  theme,
} from "../../../utils/styles";

const customSelectStyles = {
  selectStyle: {
    width: "100%",
    borderRadius: "10px",
    background: "none",
    backdropFilter: "blur(5.5px)",
    border: "none",
    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiSelect-outlined": {
      // border: "1px solid #D2D3D8",
      // position: "relative",
      // padding: "11px 12px",
      // borderRadius: "10px",
      // color: "#202730",
      fontSize: getRelativeFontSize(3),
      position: "relative",
      padding: "9px 13px",
      border: "2px solid transparent !important",
      background:
        "linear-gradient(to right, white, white), linear-gradient(45deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%) !important",
      opacity: 1,
      WebkitBackgroundClip: "padding-box, border-box !important",
      backgroundOrigin: "padding-box, border-box !important",
      borderRadius: "8px",
      color: "#222222",

      "&::placeholder": {
        ...mediumFont,
        color: "#DFDFDF",
      },
    },
    "& .MuiSelect-icon": {
      color: "#DFDFDF",
    },
    // "& ..MuiOutlinedInput-notchedOutline": {
    //   border: "none !important",
    // },
  },
  nameField: {
    ...mediumFont,
    color: primaryColorWhite,
    fontSize: getRelativeFontSize(4),
    "& .MuiFormLabel-asterisk": {
      color: inputLabelRequiredColor,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  icon: {
    fill: "#202730",
  },
  errorStyle: {
    ...mediumFont,
    fontSize: getRelativeFontSize(1),
    marginLeft: "6px",
    color: "#d32f2f",
  },
  errorTextField: {
    "& .MuiSelect-outlined": {
      border: "1px solid #d32f2f",
      borderRadius: "10px",
      position: "relative",
      padding: "11px 12px",
      color: "#FFFFFF",
    },
  },
} as const;

export default customSelectStyles;
