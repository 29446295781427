import { Box, Divider, Drawer, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CustomButton from "global/components/CustomButton/CustomButton";
import viewpaths from "global/constants/ViewPathConstants";
import { usePathname } from "hooks/use-pathname";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import history from "utils/history";
import { animationStyle } from "utils/styles";
import { Section } from "../Config";

const MOBILE_NAV_WIDTH = 280;

const useCssVars = (): Record<string, string> => {
  const theme = useTheme();

  return useMemo((): Record<string, string> => {
    if (theme.palette.mode === "dark") {
      return {
        "--nav-bg": theme.palette.background.default,
        "--nav-color": theme.palette.neutral[100],
        "--nav-divider-color": theme.palette.neutral[800],
        "--nav-border-color": theme.palette.neutral[700],
        "--nav-logo-border": theme.palette.neutral[700],
        "--nav-item-color": theme.palette.neutral[400],
        "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-color": theme.palette.text.primary,
        "--nav-item-disabled-color": theme.palette.neutral[600],
        "--nav-item-icon-color": theme.palette.neutral[500],
        "--nav-item-icon-active-color": theme.palette.primary.main,
        "--nav-item-icon-disabled-color": theme.palette.neutral[700],
        "--nav-item-chevron-color": theme.palette.neutral[700],
        "--nav-scrollbar-color": theme.palette.neutral[400],
      };
    } else {
      return {
        "--nav-bg": "#242A3A",
        "--nav-color": "#FFFFFF",
        "--nav-divider-color": theme.palette.divider,
        "--nav-border-color": theme.palette.neutral[100],
        "--nav-logo-border": theme.palette.neutral[100],
        "--nav-section-title-color": theme.palette.neutral[400],
        "--nav-item-color": "#5E6979",
        "--nav-item-hover-bg": "#474C5B",
        "--nav-item-active-bg": "#474C5B",
        "--nav-item-active-color": "#FFFFFF",
        "--nav-item-disabled-color": theme.palette.neutral[400],
        "--nav-item-icon-color": "#5E6979",
        "--nav-item-icon-active-color": "#FFFFFF",
        "--nav-item-icon-disabled-color": theme.palette.neutral[400],
        "--nav-item-chevron-color": theme.palette.neutral[400],
        "--nav-scrollbar-color": theme.palette.neutral[900],
      };
    }
  }, [theme]);
};

interface CustomProps {
  onClose?: () => void;
  open?: boolean;
  sections?: Section[];
}

const animation: any = makeStyles(animationStyle);

const MobileNav = (props: CustomProps) => {
  const pathname = usePathname();
  const cssVars = useCssVars();
  const { t } = useTranslation();
  const animationClasses = animation();

  const navMenu = [
    {
      label: `${t("home")}`,
      url: viewpaths.landingViewPath,
    },
    {
      label: `${t("about")}`,
      url: viewpaths.aboutViewPath,
    },
    {
      label: `${t("calculators")}`,
      url: viewpaths.calculatorsViewPath,
    },
    {
      label: `${t("resources")}`,
      url: viewpaths.resourcesViewPath,
    },
    {
      label: `${t("contact")}`,
      url: viewpaths.contactViewPath,
    },
  ];

  return (
    <Drawer
      anchor="top"
      onClose={props.onClose}
      open={props.open}
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: "var(--nav-bg)",
          color: "var(--nav-color)",
          // width: MOBILE_NAV_WIDTH,
        },
      }}
      variant="temporary"
    >
      <Stack sx={{ height: "100%" }}>
        {/* <Stack alignItems="center" direction="row" spacing={2} sx={{ p: 3 }}>
          <Box
            textAlign={"center"}
            onClick={() => history.push(viewpaths.landingViewPath)}
          >
            <img src={Logo} style={{ width: "70%" }} />
          </Box>
        </Stack> */}
        <Stack
          component="nav"
          sx={{
            flexGrow: 1,
            p: 2,
          }}
        >
          {navMenu.map((menuItem, index) => (
            <Box className={animationClasses.fadeInUp}>
              <CustomButton
                label={menuItem.label}
                buttonType="transparentBtn"
                onClick={() => history.push(menuItem.url)}
              />
              {index !== navMenu.length - 1 && (
                <Divider sx={{ border: "1px solid #ffffff30", my: 1 }} />
              )}
            </Box>
          ))}
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default MobileNav;
