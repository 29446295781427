import {
  getRelativeFontSize,
  mediumFont,
  primaryColorWhite,
  regularFont,
  theme,
} from "utils/styles";
import contactUsBackImage from "../../assets/images/contactUsBackImage.svg";

const resourcesStyle = {
  backImg: {
    minHeight: "62vh",
    height: "100%",
    background: `url(${contactUsBackImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
  innerBackground: {
    backgroundColor: "rgba(51,59,79,0.98)",
    color: "inherit",
    position: "absolute",
    width: "100%",
    height: "100% !important",
    left: 0,
    top: 0,
  },
  headerText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(50),
    color: primaryColorWhite,
    lineHeight: "5.5rem",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(40),
      lineHeight: "5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(30),
      lineHeight: "4.5rem",
    },
  },
  subHeaderText: {
    ...regularFont,
    fontSize: getRelativeFontSize(4),
    color: "#c7c8c9",
    linHeight: "19px",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(3),
    },
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  contentHeaderText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(16),
    color: primaryColorWhite,
    textAlign: "center",
    lineHeight: 1.4,
    maxWidth: "1000px",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(8),
    },
  },
  gradientText: {
    background:
      "linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    ...mediumFont,
    fontSize: getRelativeFontSize(12),
    width: "fit-content",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(10),
    },
  },
  darkBackground: {
    background: "#2f3749",
    position: "relative",
    boxShadow: "inset 0px 12px 4px -11px #474a51",
    zIndex: 1,
    py: 15,
  },
  reasonBox: {
    height: "100%",
    width: "100%",
    borderRadius: "5px",
    background:
      "linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%)",
    padding: "1px",
    boxSizing: "border-box",
    cursor: "pointer",
  },
  greyBox: {
    height: "100%",
    width: "100%",
    borderRadius: "6px",
    backgroundColor: "#30384a",
  },
  resourcesImage: {
    width: "100%",
    height: "350px",
    objectFit: "cover",
  },
  titleText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(10),
    color: primaryColorWhite,
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(6),
    },
  },
  descriptionText: {
    ...regularFont,
    fontSize: getRelativeFontSize(3),
    color: "#c7c8c9",
    linHeight: "19px",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  authorText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(2),
    color: "#797e89",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(0),
    },
  },
  questionText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(8),
    color: "#ffffffe0",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(4),
    },
  },
  answerText: {
    ...regularFont,
    fontSize: getRelativeFontSize(3),
    color: "#c7c8c9",
    linHeight: "19px",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(1),
    },
  },
  contactBtn: {
    border: "none",
    minWidth: "200px",
    height: "50px !important",
  },
} as const;

export default resourcesStyle;
