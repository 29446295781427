import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Container,
  IconButton,
  Stack,
  SvgIcon,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { useMobileNav } from "hooks/use-mobile-nav";
import { useEffect, useState } from "react";
import { theme } from "utils/styles";
import TopNavSection from "./TopNavSection";

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

interface CustomProps {
  onMobileNavOpen: () => void;
  sections?: any;
}

const TopNav = (props: CustomProps) => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const mobileNav = useMobileNav();
  const [bgColor, setBgColor] = useState("transparent");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setBgColor("#414b5ce6");
      } else {
        setBgColor("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      component="header"
      sx={{
        height: bgColor !== "transparent" ? "60px" : "100px",
        width: "100%",
        backgroundColor: bgColor,
        position: "fixed",
        top: 0,
        backdropFilter: bgColor === "transparent" ? "blur(0px)" : "blur(8px)",
        zIndex: (theme) => theme.zIndex.appBar,
        boxShadow:
          bgColor !== "transparent"
            ? "0px 4px 12px rgba(0, 0, 0, 0.1)"
            : "none",
        transition: "all 0.3s ease",
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="center"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: "90px",
          height: "100%",
          [theme.breakpoints.down(1000)]: {
            px: "20px",
          },
        }}
      >
        <Container maxWidth="xl">
          <TopNavSection />
        </Container>
        <Stack alignItems="center" direction="row" spacing={2}>
          {!mdUp && (
            <IconButton onClick={props.onMobileNavOpen}>
              <SvgIcon>
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default TopNav;
