import { theme } from "../../../utils/styles";

const customDialogStyles = {
  headerStyle: {
    background: "#1b202a",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0 0 0",
    position: "relative",
    width: "100%",
    borderBottom: "1px solid #FFFFFF",
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  closeButtonContainer: {
    position: "absolute",
    top: "17px",
    right: "15px",
    color: theme.palette.grey[500],
  },
  closeButton: {
    padding: 0,
    marginTop: "9px",
    marginRight: "5px",
    color: "#ffffff",
    border: "1px solid #ffffff",
  },
  dialogContent: {
    padding: theme.spacing(2),
    background: "#1b202a",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      border: "3px solid #ffffff",
    },
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing(1, 2),
    display: "flex",
  },
  displayFlex: {
    display: "flex",
  },
};

export default customDialogStyles;
