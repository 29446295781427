import { Box, Container, Stack, Typography } from "@mui/material";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import AnimatedSection from "screens/Shared/AnimationSection";
import { theme } from "utils/styles";
import leftArrow from "../../../assets/icons/leftArrow.svg";
import quotIcon from "../../../assets/icons/quotIcon.svg";
import rightArrow from "../../../assets/icons/rightArrow.svg";
import userIcon from "../../../assets/icons/userIcon.svg";
import homeStyles from "../Home.styles";

const TestimonialsSection = () => {
  const classes = homeStyles;
  const { t } = useTranslation();
  const scrollBoxRef = useRef<HTMLDivElement | null>(null);

  const scrollLeft = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({
        left: -window.innerWidth * 0.5, // 30vw to the left
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollBy({
        left: window.innerWidth * 0.5, // 30vw to the right
        behavior: "smooth",
      });
    }
  };
  return (
    <Box mb={15}>
      <Container maxWidth="lg">
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={5}
        >
          <Typography sx={classes.gradientText}>{t("testimonials")}</Typography>
          <Container maxWidth="md">
            <AnimatedSection animationType="fadeInUp">
              <Typography sx={classes.contentHeaderText}>
                {t("testimonialsHeader")}
              </Typography>
            </AnimatedSection>
          </Container>
        </Stack>
      </Container>
      <AnimatedSection animationType="fadeInLeft">
        <Stack
          ref={scrollBoxRef}
          direction={"row"}
          spacing={4}
          mt={10}
          sx={classes.testimonialsWrapper}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index: number) => {
            return (
              <Stack
                key={index}
                direction={"column"}
                spacing={4}
                sx={{
                  ...classes.testimonialBox,
                  ml: index === 1 ? "160px !important" : 0,
                  mr: index === 10 ? "40px !important" : 0,
                  [theme.breakpoints.down("md")]: {
                    ml: "20px !important",
                    padding: "10px 20px",
                    minWidth: "350px",
                  },
                }}
              >
                <Box
                  component="img"
                  src={quotIcon}
                  alt={"icon"}
                  sx={classes.quotIcon}
                />
                <Typography
                  sx={{
                    ...classes.calculatorHeader,
                    fontSize: "22px",
                    zIndex: 1,
                    mt: "20px !important",
                  }}
                >
                  {t(`testimonial${index}`)}
                </Typography>
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  sx={{ zIndex: 1 }}
                >
                  <Box
                    component="img"
                    src={userIcon}
                    alt={"icon"}
                    sx={{
                      height: "20px",
                      background:
                        "linear-gradient(90deg, #ff68cc29 0%, #dc5fff21 33%, #a080ff2e 67%, #7f99ff70 100%) !important",
                      borderRadius: "50%",
                      padding: "10px",
                      zIndex: 1,
                    }}
                  />
                  <Stack direction={"column"} sx={{ zIndex: 1 }}>
                    <Typography
                      sx={{ ...classes.calculatorHeader, fontSize: "15px" }}
                    >
                      {t(`testimonial${index}Name`)}
                    </Typography>
                    <Typography
                      sx={{ ...classes.subHeaderText, fontSize: "14px" }}
                    >
                      {t(`testimonial${index}Profession`)}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </AnimatedSection>
      <Stack
        id="buttonBox"
        direction={"row"}
        justifyContent={"end"}
        spacing={5}
        mt={5}
        mx={5}
      >
        <Box
          component="img"
          src={leftArrow}
          alt={"icon"}
          onClick={scrollLeft}
          sx={{
            height: "20px",
            background:
              "linear-gradient(90deg, #ff68cc29 0%, #dc5fff21 33%, #a080ff2e 67%, #7f99ff70 100%) !important",
            borderRadius: "50%",
            padding: "10px",
            cursor: "pointer",
          }}
          className="hvr-bounce-in"
        />
        <Box
          component="img"
          src={rightArrow}
          alt={"icon"}
          onClick={scrollRight}
          sx={{
            height: "20px",
            background:
              "linear-gradient(90deg, #ff68cc29 0%, #dc5fff21 33%, #a080ff2e 67%, #7f99ff70 100%) !important",
            borderRadius: "50%",
            padding: "10px",
            cursor: "pointer",
          }}
          className="hvr-bounce-in"
        />
      </Stack>
    </Box>
  );
};

export default TestimonialsSection;
