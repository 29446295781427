import contactUsBackImage from "../../assets/images/contactUsBackImage.svg";
import {
  centerItemFlex,
  getRelativeFontSize,
  mediumFont,
  primaryColorWhite,
  regularFont,
  theme,
} from "../../utils/styles";

const privacyPolicyStyles = {
  backImg: {
    minHeight: "62vh",
    height: "100%",
    background: `url(${contactUsBackImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
  darkBackground: {
    background: "#2f3749",
    // background:
    //   "-webkit-linear-gradient(top, rgb(56, 64, 83) 0%, rgb(47, 55, 73) 100%)",
    position: "relative",
    boxShadow: "inset 0px 12px 4px -11px #474a51",
  },
  innerBackground: {
    backgroundColor: "rgba(51,59,79,0.98)",
    color: "inherit",
    position: "absolute",
    width: "100%",
    height: "100% !important",
    left: 0,
    top: 0,
  },
  headerText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(50),
    color: primaryColorWhite,
    lineHeight: "5.5rem",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(40),
      lineHeight: "5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(30),
      lineHeight: "4.5rem",
    },
  },
  subHeaderText: {
    ...regularFont,
    fontSize: getRelativeFontSize(4),
    color: "#c7c8c9",
    linHeight: "19px",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(3),
    },
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  headingTypo: {
    ...mediumFont,
    fontSize: getRelativeFontSize(4),
    color: "#ffffff",
  },
  subHeadingTypo: {
    ...mediumFont,
    fontSize: getRelativeFontSize(3),
    color: "#ffffff",
  },
  normalTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#c7c8c9",
  },
  linkTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#c7c8c9",
    cursor: "pointer",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    msWordBreak: "break-all",
    wordBreak: "break-word",
    msHyphens: "auto",
    MozHyphens: "auto",
    WebkitHyphens: "auto",
    hyphens: "auto",
  },
  pointsTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#c7c8c9",
    display: "list-item",
    listStyle: "disc",
    ml: 2.5,
  },
  innerPointsTypo: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#c7c8c9",
    display: "list-item",
    listStyleType: "disclosure-closed",
    ml: 4.5,
  },
  decimalPointsTypo: {
    fontSize: getRelativeFontSize(2),
    color: "#c7c8c9",
    ...regularFont,
    ml: 2.5,
    position: "relative",
    paddingLeft: "15px",
  },
  customDecimal: {
    fontSize: getRelativeFontSize(2),
    color: "#c7c8c9",
    position: "absolute",
    left: -20,
  },
  footer: {
    ...centerItemFlex,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
  footerTypo: {
    ...mediumFont,
    fontSize: getRelativeFontSize(0),
    textAlign: "center",
    color: "#FFFFFF",
  },
} as const;

export default privacyPolicyStyles;
