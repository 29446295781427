import {
  getRelativeFontSize,
  mediumFont,
  primaryColorWhite,
  regularFont,
  theme,
} from "utils/styles";
import achievementsBackImage from "../../assets/images/achievementsBackImage.jpg";
import contactImage from "../../assets/images/contactImage.jpg";
import heroBackgroundImage from "../../assets/images/gradientdark_1.png";
import lineDecorationImage from "../../assets/images/lineDecorationImage.png";
import trustedByBackImage from "../../assets/images/trustedByBackImage.webp";

const homeStyles = {
  backImg: {
    minHeight: "100vh",
    height: "100%",
    background: `url(${heroBackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(50),
    color: primaryColorWhite,
    lineHeight: "5.5rem",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(40),
      lineHeight: "5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(30),
      lineHeight: "4.5rem",
    },
  },
  subHeaderText: {
    ...regularFont,
    fontSize: getRelativeFontSize(4),
    color: "#c7c8c9",
    linHeight: "19px",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(3),
    },
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  ourServiceBtn: {
    border: "none",
    minWidth: "200px",
    height: "50px !important",
  },
  downArrowWrapper: {
    padding: "10px 12px",
    border: "1px solid #c7c8c9",
    borderRadius: "50%",
  },
  scrollDownText: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#c7c8c9",
  },
  gradientText: {
    background:
      "linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    ...mediumFont,
    fontSize: getRelativeFontSize(12),
    width: "fit-content",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(10),
    },
  },
  heroDecorationWrapper: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    margin: 0,
    backgroundColor: "transparent",
  },
  heroDecorationImage1: {
    position: "relative",
    height: "0px",
    width: "0px",
    top: "170px",
    left: "150px",
    zIndex: 0,
    [theme.breakpoints.down("lg")]: {
      top: "100px",
      left: "80px",
    },
  },
  heroImage: {
    width: "100%",
    height: "100%",
    maxHeight: "550px",
    maxWidth: "550px",
    position: "relative",
    zIndex: 1,
  },
  heroDecorationImage2: {
    position: "relative",
    height: "0px",
    width: "0px",
    bottom: "275px",
    right: "140px",
    zIndex: 0,
    [theme.breakpoints.down("lg")]: {
      bottom: "200px",
      right: "120px",
    },
  },
  contentHeaderText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(36),
    color: primaryColorWhite,
    textAlign: "center",
    lineHeight: 1.2,
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(15),
    },
  },
  decorationBackImg: {
    background: `url(${lineDecorationImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
  },
  calculatorHeader: {
    ...mediumFont,
    fontSize: getRelativeFontSize(5),
    color: primaryColorWhite,
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(3),
    },
  },
  calculatorWrapper: {
    cursor: "pointer",
    position: "relative",
    p: "30px 0px 30px 30px",
  },
  selectedCalculatorWrapper: {
    "&::before": {
      content: '""',
      position: "absolute",
      left: -1,
      top: 0,
      bottom: 0,
      width: "1px",
      background:
        "linear-gradient(180deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%)",
      borderRadius: "2px",
    },
  },
  contactBackground: {
    position: "relative",
    backgroundImage: `url(${contactImage})`,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    backgroundSize: "cover",
    backgroundPosition: "center",
    bottom: 0,

    "&:before": {
      zIndex: 0,
      position: "absolute",
      background:
        "linear-gradient(90deg, rgb(0 0 0 / 0%) 0%, rgb(48 56 75 / 70%) 50%, rgb(48 56 74) 100%)",
      content: `""`,
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
      [theme.breakpoints.down("md")]: {
        background:
          "linear-gradient(90deg, rgb(48 56 74) 0%, rgb(48 56 75 / 70%) 50%, rgb(48 56 74) 100%)",
      },
    },
  },
  aboutStudioImage: {
    borderRadius: "5px",
    position: "relative",
    zIndex: 1,
    width: "100%",
    height: "auto",
    maxHeight: "300px",
    [theme.breakpoints.down("md")]: {
      maxHeight: "200px",
    },
    "@supports (-webkit-touch-callout: none)": {
      height: "auto !important",
      maxWidth: "100%",
      maxHeight: "300px",
      [theme.breakpoints.down("md")]: {
        maxHeight: "200px",
      },
    },
  },
  gradientBox: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    background:
      "linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%)",
    padding: "1px",
    boxSizing: "border-box",
    transform: "translate(-15px, -15px)",
  },
  achievementsBackground: {
    position: "relative",
    backgroundImage: `url(${achievementsBackImage})`,
    height: "100%",
    width: "95%",
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    backgroundSize: "cover",
    backgroundPosition: "center",
    bottom: 0,
    borderRadius: "10px",
    margin: "0 auto",

    "&:before": {
      zIndex: 0,
      position: "absolute",
      background:
        "linear-gradient(90deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 55%) 50%, rgb(0 0 0 / 25%) 100%)",
      content: `""`,
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
      borderRadius: "10px",
    },
  },
  serviceWrapper: {
    height: "100%",
    width: "95%",
    borderRadius: "5px",
    background:
      "linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%)",
    padding: "1px",
    boxSizing: "border-box",
    margin: "0 auto",
    mt: 10,
  },
  greyBox: {
    height: "100%",
    width: "100%",
    borderRadius: "6px",
    backgroundColor: "#30384a",
  },
  serviceImage1: {
    width: "auto",
    height: "200px",
    borderRadius: "10px",
    position: "relative",
    transition: "transform 0.1s ease-out",
    bottom: "30px",
    left: "50px",
    [theme.breakpoints.down("lg")]: {
      height: "120px",
      bottom: "0px",
    },
  },
  serviceImage2: {
    width: "auto",
    height: "200px",
    borderRadius: " 10px",
    position: "relative",
    transition: "transform 0.1s ease-out",
    top: "30px",
    right: "50px",
    [theme.breakpoints.down("lg")]: {
      height: "120px",
    },
  },
  calculatorsImage: {
    width: "100%",
    height: "600px",
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },
  reasonBox: {
    height: "100%",
    width: "100%",
    borderRadius: "5px",
    background:
      "linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%)",
    padding: "1px",
    boxSizing: "border-box",
  },
  trustedByBackground: {
    position: "relative",
    backgroundImage: `url(${trustedByBackImage})`,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    backgroundSize: "cover",
    backgroundPosition: "center",
    bottom: 0,

    "&:before": {
      zIndex: 0,
      position: "absolute",
      background: "rgba(47,55,73,0.86)",
      content: `""`,
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  },
  testimonialsWrapper: {
    overflowY: "auto",
    width: "100%",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  testimonialBox: {
    background: "#414a5e",
    padding: "20px 50px",
    border: "1px solid rgba(255,255,255,.1275)",
    borderRadius: "6px",
    position: "relative",
    minWidth: "500px",
    height: "fit-content",
  },
  quotIcon: {
    height: "70px",
    position: "absolute",
    top: "5px",
    left: "15px",
    opacity: 0.1,
  },
  scroller: {
    maxWidth: "100%",
    overflow: "hidden",
    "&.animated": {
      "-webkit-mask":
        "linear-gradient(90deg, transparent, white 20%, white 80%, transparent)",
      mask: "linear-gradient(90deg, transparent, white 20%, white 80%, transparent)",
    },
    ".scroller__inner": {
      display: "flex",
      paddingBlock: "1rem",
      width: "max-content",
      flexWrap: "nowrap",
      animation: "scroll 60s linear infinite",
    },
  },
  viewServiceBtn: {
    minWidth: "200px",
    height: "50px !important",
  },
} as const;

export default homeStyles;
