export const years: any[] = [];

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

for (let i = currentYear + 15; i > 1900; i--) {
  years.push({ value: i, content: i });
}

export const days: any[] = [];

for (let i = 1; i <= 31; i++) {
  days.push({ value: i, content: i });
}

export const hours: any[] = [];

for (let i = 1; i <= 12; i++) {
  if (i === 12) {
    hours.push({ content: i + " AM", value: 0 });
  } else {
    hours.push({ content: i + " AM", value: i });
  }
}

for (let i = 1; i <= 12; i++) {
  if (i === 12) {
    hours.push({ content: i + " PM", value: 12 });
  } else {
    hours.push({ content: i + " PM", value: i + 12 });
  }
}

export const minutesSeconds: any[] = [];

for (let i = 0; i <= 59; i++) {
  minutesSeconds.push({ value: i, content: i });
}

export const formData = () => {
  return {
    firstName: {
      value: "",
      error: "",
    },
    bloodGroup: {
      value: "0",
      error: "",
    },
    sex: {
      value: "Man",
      error: "",
    },
    year: {
      value: "0",
      error: "",
    },
    month: {
      value: "0",
      error: "",
    },
    date: {
      value: "0",
      error: "",
    },
    hours: {
      value: "-1",
      error: "",
    },
    minutes: {
      value: "-1",
      error: "",
    },
    seconds: {
      value: "0",
      error: "",
    },
    birthPlace: {
      value: "",
      error: "",
    },
    key: {
      value: "",
      error: "",
    },
    latitude: {
      value: "",
      error: "",
    },
    longitude: {
      value: "",
      error: "",
    },
    timezone: {
      value: "",
      error: "",
    },
    pregnancy: {
      value: "9",
      error: "",
    },
  } as any;
};

export const formValidation = (formData: any, address?: any) => {
  let errors = formData;
  let isValid = true;
  const firstName = formData.firstName.value;
  const year = formData.year.value;
  const month = formData.month.value;
  const date = formData.date.value;
  const hours = formData.hours.value;
  const minutes = formData.minutes.value;
  const seconds = formData.seconds.value;
  const birthPlace = formData.birthPlace.value;
  const latitude = address.latitude;
  const longitude = address.longitude;
  if (!firstName) {
    errors.firstName.error = "Please enter name";
    isValid = false;
  }
  if (year === "0") {
    errors.year.error = "Please select birth year";
    isValid = false;
  }
  if (month === "0") {
    errors.month.error = "Please select birth month";
    isValid = false;
  }
  if (date === "0") {
    errors.date.error = "Please select birth date";
    isValid = false;
  }
  if (hours === "-1") {
    errors.hours.error = "Please select birth hours";
    isValid = false;
  }
  if (minutes === "-1") {
    errors.minutes.error = "Please select birth minutes";
    isValid = false;
  }
  if (seconds === "-1") {
    errors.seconds.error = "Please select birth seconds";
    isValid = false;
  }
  if (!birthPlace) {
    errors.birthPlace.error = "Please select birth place";
    isValid = false;
  }
  if (!latitude) {
    errors.birthPlace.error = "Please select birth place";
    isValid = false;
  }
  if (!longitude) {
    errors.birthPlace.error = "Please select birth place";
    isValid = false;
  }
  return { isValid, errors };
};
