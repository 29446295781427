import { alpha } from "@mui/material/styles";
import { backdropClasses } from "@mui/material/Backdrop";
import { filledInputClasses } from "@mui/material/FilledInput";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { paperClasses } from "@mui/material/Paper";
import { tableCellClasses } from "@mui/material/TableCell";
import { common } from "@mui/material/colors";
import type { Components } from "@mui/material/styles/components";
import type {
  PaletteColor,
  PaletteOptions,
} from "@mui/material/styles/createPalette";

interface Config {
  palette: PaletteOptions;
}

export const createComponents = ({ palette }: Config): Components => {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.neutral![200],
          color: common.black,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          [`&:not(.${backdropClasses.invisible})`]: {
            backgroundColor: alpha(palette.neutral![900], 0.75),
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          [`&.${paperClasses.elevation1}`]: {
            boxShadow:
              "0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
            background: "#FFFFFF",
            borderRadius: "20px",
            border: "0.5px solid #E9E9E9",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        icon: {
          color: palette.action!.active,
        },
        root: {
          borderColor: palette.neutral![200],
          backgroundColor: "#DAEFC2",
          color: "#000000",
          borderRadius: "10px",
          border: "1px",
          fontWeight: 400,
          fontSize: "0.875rem",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "#nprogress .bar": {
          backgroundColor: (palette.primary as PaletteColor).main,
        },
        ".slick-dots li button": {
          "&:before": {
            fontSize: 10,
            color: (palette.primary as PaletteColor).main,
          },
        },
        ".slick-dots li.slick-active button": {
          "&:before": {
            color: (palette.primary as PaletteColor).main,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontWeight: 400,
          fontSize: "0.875rem",
          color: "#828282",
          padding: "12px 12px",
          "&::placeholder": {
            fontWeight: 400,
            fontSize: "0.875rem",
            color: "#828282",
            opacity: 1,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          fontSize: "0.875rem",
          fontWeight: 400,
          lineHeight: 1.57,
          padding : "12px 12px",
          "&::placeholder": {
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: 1.57,
            color: "#AFB4BC",
            opacity: 1,
          },
        },
        root: {
          borderRadius: "12px",
          fontWeight: 400,
          fontSize: "0.875rem",
          color: "#828282",
          borderColor: "#C8C8C8",
          "&:hover": {
            backgroundColor: palette.action!.hover,
          },
          [`&.${filledInputClasses.disabled}`]: {
            backgroundColor: "transparent",
          },
          [`&.${filledInputClasses.focused}`]: {
            backgroundColor: "transparent",
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: "1px solid",
              borderColor: "#2D3751",
            },
          },
          [`&.${filledInputClasses.error}`]: {
            borderColor: (palette.error as PaletteColor).main,
            // boxShadow: `${(palette.error as PaletteColor).main} 0 0 0 2px`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: "1rem",
          fontWeight: 400,
          lineHeight: 1.57,
          padding: "12px 12px",
          "&::placeholder": {
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.57,
            color: "#CBCBCB",
            opacity: 1,
          },
        },
        root: {
          borderRadius: "8px",
          width: "100%",
          "&:hover": {
            backgroundColor: palette.action!.hover,
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: palette.neutral![200],
            },
          },
          [`&.${outlinedInputClasses.focused}`]: {
            backgroundColor: "transparent",
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              border: "1px solid",
              borderColor: "#C8C8C8",
            },
          },
          [`&.${outlinedInputClasses.disabled}`]: {
            //  border: "1px solid",
            borderColor: palette.neutral![200],
            backgroundColor: "transparent",
          },
          [`&.${filledInputClasses.error}`]: {
            borderColor: (palette.error as PaletteColor).main,
          },
        },
        notchedOutline: {
          borderRadius: "8px",
          borderColor: "#C8C8C8",
          // borderColor: palette.neutral![200],
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: palette.neutral![500],
        },
        track: {
          backgroundColor: palette.neutral![400],
          opacity: 1,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: palette.divider,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          [`& .${tableCellClasses.root}`]: {
            backgroundColor: palette.neutral![50],
            color: palette.neutral![700],
          },
        },
      },
    },
    // @ts-ignore
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          backgroundColor: palette.divider,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backdropFilter: "blur(6px)",
          background: alpha(palette.neutral![900], 0.8),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          fontSize: "1.125rem",
          fontWeight: 500,
          background: "#2E3152",
          borderRadius: "8px",
          boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.04)",
          color: "#FFF",
          padding: "6px 16px",
          width: "172px",
          height: "48.931px",
          flexShrink: "0",
          "&:hover": {
            background: "#2E3152",
          },
        },
        outlined: {
          border: "1px solid #2E3152",
          boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.04)",
          fontSize: "1.125rem",
          fontWeight: 500,
          borderRadius: "8px",
          color: "#2E3152",
          padding: "6px 16px",
          width: "172px",
          height: "48.931px",
          flexShrink: "0",
          "&:hover": {
            border: "1px solid #2E3152",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 1.71,
          minWidth: "auto",
          paddingLeft: 0,
          paddingRight: 0,
          textTransform: "none",
          color: "#BABEC3",
          "& + &": {
            marginLeft: 24,
          },
          "&.Mui-selected": {
            fontWeight: 500,
            color: "#85C935",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#85C935",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          display: "flex",
          fontWeight: 500,
          fontSize: " 1rem",
          color: "#1E1E1E",
        },
        asterisk: {
          color: "#ff0000",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          borderRadius: "12px",
          "&:hover": {
            backgroundColor: palette.action!.hover,
          },
          [`&.${filledInputClasses.disabled}`]: {
            backgroundColor: "transparent",
          },
          [`&.${filledInputClasses.focused}`]: {
            backgroundColor: "transparent",
          },
          [`&.${filledInputClasses.error}`]: {
            borderColor: (palette.error as PaletteColor).main,
            // boxShadow: `${(palette.error as PaletteColor).main} 0 0 0 2px`,
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          background: "#85C935",
          color: "#FFFFFF",
          fontWeight: 400,
          fontSize: "0.688rem",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#828282",
          fontWeight: 400,
        },
      },
    },
  };
};
