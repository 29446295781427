import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CustomInput } from "global/components";
import CustomButton from "global/components/CustomButton/CustomButton";
import CustomLoader from "global/components/CustomLoader/CustomLoader";
import {
  handleErrorMessage,
  openErrorNotification,
  openSuccessNotification,
} from "helpers/methods";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AnimatedSection from "screens/Shared/AnimationSection";
import { animationStyle } from "utils/styles";
import addressIcon from "../../assets/icons/addressIcon.svg";
import emailIcon from "../../assets/icons/emailIcon.svg";
import contactStyle from "./Contact.styles";
import { submitContactForm } from "./ContactServices";
import LocationMap from "./LocationMap";

const animation: any = makeStyles(animationStyle);

const initialState = {
  name: {
    value: "",
    error: "",
  },
  email: {
    value: "",
    error: "",
  },
  message: {
    value: "",
    error: "",
  },
};

const Contact = () => {
  const classes = contactStyle;
  const animationClasses = animation();
  const { t } = useTranslation();
  const [formFields, setFormFields] = useState(initialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const details = [
    {
      label: `${t("address")}`,
      value:
        "Noyras Limited, Craven House, 40-44 Uxbridge Road, London W5 2BS, United Kingdom",
      button: "See map",
      icon: addressIcon,
    },
    {
      label: `${t("email")}`,
      value: "info@astroepigenetics.com",
      button: "Say hello",
      icon: emailIcon,
    },
    // {
    //   label: `${t("phone")}`,
    //   value: "+61 383 767 284",
    //   button: "Call now",
    //   icon: phoneIcon,
    // },
  ];

  const handleClick = (buttonText: string) => {
    switch (buttonText) {
      case "See map":
        // Open Google Maps in a new tab centered at Poland
        window.open(
          "https://www.google.com/maps/place/Craven+House/@51.5123072,-0.312799,17.2z/data=!3m1!5s0x48760d8ca2c090ad:0x3fc10fdd23d48a8c!4m6!3m5!1s0x48760d8986e6a057:0xfc192ef2e629dd2c!8m2!3d51.5123963!4d-0.3120367!16s%2Fg%2F11f3xhj_4t?entry=ttu&g_ep=EgoyMDI0MDgyMy4wIKXMDSoASAFQAw%3D%3D",
          "_blank"
        );
        break;
      case "Say hello":
        // Logic to send an email
        window.location.href = `mailto:${
          details.find((detail) => detail.button === buttonText)?.value
        }`;
        break;
      case "Call now":
        // Logic to initiate a call
        window.location.href = `tel:${
          details.find((detail) => detail.button === buttonText)?.value
        }`;
        break;
      default:
        console.log("Button clicked");
    }
  };

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    const newFormFields = {
      ...formFields,
      [event.target.name]: {
        value: event.target.value,
        error: "",
      },
    };
    setFormFields(newFormFields);
  };

  const handleValidation = () => {
    if (
      !formFields.name.value ||
      !formFields.email.value ||
      !formFields.message.value
    ) {
      openErrorNotification("Please fill all required fields");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    try {
      if (handleValidation()) {
        setIsLoading(true);
        const contactForm = {
          name: formFields.name.value,
          email: formFields.email.value,
          message: formFields.message.value,
        };
        await submitContactForm(contactForm);
        setFormFields(initialState);
        openSuccessNotification("Query submitted successfully");
      }
    } catch (error: any) {
      handleErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getContactHeader = () => {
    return (
      <Box
        sx={{
          background: "#2f3749",
        }}
      >
        <Box sx={classes.backImg}>
          <Box sx={classes.innerBackground}>
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
              maxHeight={"62vh"}
              spacing={2}
              className={animationClasses.fadeInDown}
            >
              <Typography sx={classes.headerText}>{t("contactUs")}</Typography>
              <Typography sx={classes.subHeaderText}>
                {t("contactUsPageSubHeader")}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
    );
  };

  const getContactForm = () => {
    return (
      <Box sx={{ background: "#2f3749", position: "relative", py: 10 }}>
        <Container maxWidth="xl">
          <Grid container spacing={{ lg: 10, md: 5, xs: 2 }}>
            <Grid
              item
              xl={5}
              lg={5}
              md={4}
              sm={12}
              xs={12}
              alignContent={"center"}
            >
              {details.map((detail, index) => {
                return (
                  <AnimatedSection animationType="fadeInLeft">
                    <Box>
                      <Divider
                        sx={{
                          border: "1px solid #4e5564",

                          position: "relative",
                        }}
                      />
                      <Stack
                        direction={"row"}
                        spacing={3}
                        alignItems={"start"}
                        sx={{ zIndex: 1, margin: "30px 0" }}
                      >
                        <Box
                          component="img"
                          src={detail.icon}
                          alt={"icon"}
                          sx={{
                            height: "25px",
                            width: "fit-content",
                            background: "#f2e4fb",
                            borderRadius: "30%",
                            padding: "10px",
                            alignSelf: "baseline",
                            zIndex: 1,
                          }}
                        />
                        <Stack
                          direction={"column"}
                          spacing={1}
                          sx={{ zIndex: 1 }}
                        >
                          <Typography
                            sx={{
                              ...classes.calculatorHeader,
                              fontSize: "18px",
                            }}
                          >
                            {detail.label}
                          </Typography>
                          <Typography
                            sx={{ ...classes.subHeaderText, fontSize: "17px" }}
                          >
                            {detail.value}
                          </Typography>
                          <Typography
                            sx={{
                              ...classes.contactButtonText,
                              cursor: "pointer",
                              ...classes.contactButtonTextHover,
                            }}
                            onClick={() => handleClick(detail.button)}
                          >
                            {detail.button}
                          </Typography>
                        </Stack>
                      </Stack>
                      {details.length - 1 === index && (
                        <Divider
                          sx={{
                            border: "1px solid #4e5564",

                            position: "relative",
                          }}
                        />
                      )}
                    </Box>
                  </AnimatedSection>
                );
              })}
            </Grid>
            <Grid item xl={7} lg={7} md={8} sm={12} xs={12}>
              <Box
                sx={{
                  background:
                    "linear-gradient(90deg, #4d3e5d 0%, #443f64 50%, #3b4564 100%) !important",
                  borderRadius: "6px",
                  position: "relative",
                  p: 4,
                }}
              >
                <AnimatedSection animationType="fadeInLeft">
                  <Typography sx={classes.contactFormHeader}>
                    {t("contactFormHeader")}
                  </Typography>
                </AnimatedSection>
                <AnimatedSection animationType="fadeInLeft">
                  <Grid container spacing={2} pt={5}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <CustomInput
                        required
                        label={t("name")}
                        name="name"
                        value={formFields.name.value}
                        placeHolder={t("namePlaceholder")}
                        onChange={handleOnChangeInputField}
                        error={formFields.name.error}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <CustomInput
                        required
                        label={t("email")}
                        name="email"
                        value={formFields.email.value}
                        placeHolder={t("emailPlaceholder")}
                        onChange={handleOnChangeInputField}
                        error={formFields.email.error}
                      />
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <CustomInput
                        required
                        multiline
                        rows={2}
                        label={t("message")}
                        name="message"
                        value={formFields.message.value}
                        placeHolder={t("messagePlaceholder")}
                        onChange={handleOnChangeInputField}
                        error={formFields.message.error}
                      />
                    </Grid>
                  </Grid>
                </AnimatedSection>
                <AnimatedSection animationType="fadeInLeft">
                  <Grid
                    container
                    spacing={2}
                    pt={5}
                    justifyContent={"space-between"}
                  >
                    <Grid item xl={8} lg={8} md={9} sm={12} xs={12}>
                      <Typography sx={classes.contactNoteText}>
                        &#9432; &nbsp;{t("contactFormNote")}
                      </Typography>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                      <CustomButton
                        label={t("sendMessage")}
                        buttonType="gradientBtn"
                        onClick={handleSubmit}
                        customClasses={classes.contactBtn}
                      />
                    </Grid>
                  </Grid>
                </AnimatedSection>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  return (
    <>
      {getContactHeader()}
      {getContactForm()}
      <LocationMap />
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default Contact;
