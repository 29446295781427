import { Typography } from "@mui/material";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { useState } from "react";

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const center = {
  lat: 51.5123963,
  lng: -0.3120367,
};
const options = {
  styles: [
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#808080" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#808080" }],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [{ color: "#46bcec" }],
    },
  ],
  disableDefaultUI: true,
  zoomControl: true,
  gestureHandling: "cooperative",
};

const customMarkerIcon: any = {
  path: "M12 2C8.13 2 5 5.13 5 9c0 4.24 7 13 7 13s7-8.76 7-13c0-3.87-3.13-7-7-7zm0 10.5c-1.93 0-3.5-1.57-3.5-3.5S10.07 5.5 12 5.5s3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z",
  fillColor: "#000000",
  fillOpacity: 1,
  strokeWeight: 1,
  strokeColor: "#FFFFFF",
  scale: 2.5,
  anchor: { x: 12, y: 24 },
};

const Map = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCTkF9hPY1CXSk7voqCdHZp7ci-4PG6tHU",
  });
  const [selectedMarker, setSelectedMarker] = useState<any>(null);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={6}
      center={center}
      options={options}
    >
      <Marker
        position={center}
        icon={customMarkerIcon}
        onClick={() => setSelectedMarker(center)}
      />
      {selectedMarker && (
        <InfoWindow
          position={center}
          options={{ disableAutoPan: true }}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <Typography variant="h5">
            Noyras Limited, Craven House, 40-44 Uxbridge Road, London W5 2BS,
            United Kingdom
          </Typography>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default Map;
