export class StringConstants {
  PROD = false;

  //contentTypes
  applicationJSON = { "Content-Type": "application/json" };
  fileJSON = { "Content-Type": "text/csv" };
  multipartForm = {};

  notification = "notification";
  error = "error";
  success = "success";
  warning = "warning";
  info = "info";
  autoHideDuration = 600 * 1000; //in milliseconds

  general = "General";
  users = "Users";
  roleManagement = "Role Management";
  security = "Security";

  //buttons
  yes = "Yes";
  no = "No";
  submit = "Submit";
  createRole = "Create Role";
  editRole = "Edit Role";
  toSchoolErp = "Learning Board";
  previous = "Previous";
  next = "Next";
  signIn = "Sign In";
  getRegistered = "Get Registered";
  login = "Log In";
  backToHome = "Back To Home";
  add = "Add";
  update = "Update";
  delete = "Delete";

  LOGIN = "login";
  REGISTER = "register";
  FORGOT_PASSWORD = "forgot-password";
  RESET_PASSWORD = "changepwd";

  // Regex
  EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

  //Email validation Regex
  regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  regexPassword =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,100}$/;
  //Phone Number Regex
  phoneNumber = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  // Time-Out
  SEARCH_TIME_OUT = 1000;

  //component name Pages
  DASHBOARD = "Dashboard";
  SETTINGS = "Settings";
  HOLIDAYCALENDER = "Holiday Calender";

  //file types
  jpeg = ".jpeg";
  JPEG = ".JPEG";
  JPG = ".JPG";
  jpg = ".jpg";
  png = ".png";
  PNG = ".PNG";
  gif = ".gif";
  bmp = ".bmp";
  tiff = ".tiff";
  pdf = ".pdf";
  PDF = ".PDF";
  doc = ".doc";
  docx = ".docx";
  txt = ".txt";
  xls = ".xls";
  xlsx = ".xlsx";
  FileUrlPrefix = `https://view.officeapps.live.com/op/embed.aspx?src=`;

  // Resources
  app = "app";
  account = "account";
  document = "document";

  // Permissions
  adminPermission = "admin";
  selfPermission = "self";
  fetchPermission = "fetch";
  addPermission = "add";
  updatePermission = "update";
  uploadPermission = "upload";
  deletePermission = "delete";
  inactivatePermission = "inactivate";
  activatePermission = "activate";

  Country = "India";

  ALLOWED_RESUME_TYPE = [
    this.jpeg,
    this.gif,
    this.jpg,
    this.pdf,
    this.png,
    this.JPEG,
    this.JPG,
    this.PDF,
    this.PNG,
    this.txt,
  ];

  siteKey = "6LcfPTQiAAAAAEiV_UD6vAZCy2RkJ1heocnrPFSq";
}

let strings = new StringConstants();
export default strings;
