import {
  getRelativeFontSize,
  mediumFont,
  primaryColorWhite,
  regularFont,
  theme,
} from "utils/styles";
import footerImage from "../../assets/images/footerImage.svg";

const drawerLayoutStyle = {
  menuWrapper: {
    background: "#373f4efc",
    minWidth: "150px",
    borderRadius: "3px",
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    px: 0,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "#373f4efc",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
  menuItemText: {
    ...regularFont,
    color: primaryColorWhite,
    fontSize: getRelativeFontSize(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(1),
    },
  },
  footerWrapper: {
    bottom: 0,
    position: "relative",
    backgroundImage: `url(${footerImage})`,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    backgroundSize: "cover",
    backgroundPosition: "center",
    zIndex: 2,
    pt: 12,
    [theme.breakpoints.down("md")]: {
      pt: 4,
    },
  },
  footerHeader: {
    ...mediumFont,
    fontSize: getRelativeFontSize(20),
    color: primaryColorWhite,
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(15),
    },
  },
  footerSubHeader: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#c7c8c9",
    width: "fit-content",
  },
  footerSubHeaderHover: {
    display: "inline-block",
    verticalAlign: "middle",
    transform: "perspective(1px) translateZ(0)",
    boxShadow: "0 0 1px rgba(0, 0, 0, 0)",
    position: "relative",
    overflow: "hidden",
    "&:before": {
      content: '""',
      position: "absolute",
      zIndex: -1,
      left: "51%",
      right: "51%",
      bottom: 0,
      backgroundColor: "#c7c8c9",
      color: "#ffffff",
      height: "1px",
      transitionProperty: "left, right",
      transitionDuration: "0.3s",
      transitionTimingFunction: "ease-out",
    },
    "&:hover:before, &:focus:before, &:active:before": {
      left: 0,
      right: 0,
    },
    "&:hover, &:focus, &:active": {
      color: "#ffffff",
    },
  },
  pageHeader: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: primaryColorWhite,
  },
  fadeInUp: {
    animation: `slideDown 1s ease-out forwards`,
    opacity: 0,
    animationDelay: "0.5s",
  },
  "@global": {
    "@keyframes slideDown": {
      "0%": {
        transform: "translateY(-50px)",
        opacity: 0,
      },
      "100%": {
        transform: "translateY(0)",
        opacity: 1,
      },
    },
  },
} as const;

export default drawerLayoutStyle;
