import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { StylesProvider } from "@mui/styles";
import App from "App";
import ErrorBoundary from "global/components/ErrorBoundary/ErrorBoundary";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "serviceWorker";
import smoothscroll from "smoothscroll-polyfill";
import { createTheme } from "theme";
import { store } from "utils/store";

// steps to override default smooth scrolling behaviour in browsers
declare global {
  interface Window {
    __forceSmoothScrollPolyfill__: boolean;
  }
}
window.__forceSmoothScrollPolyfill__ = true;
smoothscroll.polyfill();

const theme = createTheme({
  colorPreset: "schoolapp",
  contrast: "normal",
  direction: "ltr",
  paletteMode: "light",
  responsiveFontSizes: true,
});

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <StylesProvider>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <React.StrictMode>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </React.StrictMode>
        </Provider>
      </ThemeProvider>
    </StylesProvider>
  </StyledEngineProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
