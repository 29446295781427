import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AnimatedSection from "screens/Shared/AnimationSection";
import image3 from "../../../assets/images/img1.jpeg";
import image2 from "../../../assets/images/img2.jpg";
// import image3 from "../../../assets/images/content10.jpg";
// import image4 from "../../../assets/images/content11.jpg";
// import image1 from "../../../assets/images/content8.jpg";
// import image2 from "../../../assets/images/content9.jpg";
import homeStyles from "../Home.styles";

const AboutStudioSection = ({
  section2Ref,
}: {
  section2Ref: React.RefObject<HTMLElement>;
}) => {
  const classes = homeStyles;
  const { t } = useTranslation();

  return (
    <Box ref={section2Ref}>
      <Container maxWidth="md" sx={{ pt: 15 }}>
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={5}
        >
          <Typography sx={classes.gradientText}>{t("aboutStudio")}</Typography>
          <AnimatedSection animationType="fadeInUp">
            <Typography sx={classes.contentHeaderText}>
              {t("aboutStudioHeader")}
            </Typography>
          </AnimatedSection>
          <AnimatedSection animationType="fadeInUp">
            <Stack direction={{ md: "row", xs: "column" }} spacing={5}>
              <Typography sx={classes.subHeaderText}>
                {t("aboutStudioContent1")}
              </Typography>
              <Typography sx={classes.subHeaderText}>
                {t("aboutStudioContent2")}
              </Typography>
            </Stack>
          </AnimatedSection>
        </Stack>
      </Container>
      <Box sx={classes.decorationBackImg} py={8} pb={15}>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={4}
            alignItems={"center"}
            width={"100%"}
            margin={"0 auto"}
          >
            {[image3, image2].map((image, index) => (
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                textAlign={"center"}
                position="relative"
                key={index}
              >
                <AnimatedSection animationType="zoomOut">
                  <Box sx={{ position: "relative", display: "inline-block" }}>
                    <Box
                      component="img"
                      src={image}
                      alt={`image-${index}`}
                      sx={classes.aboutStudioImage}
                    />
                    <Box sx={classes.gradientBox}>
                      <Box sx={classes.greyBox} />
                    </Box>
                  </Box>
                </AnimatedSection>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default AboutStudioSection;
