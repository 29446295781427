import {
  Box,
  Collapse,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AnimatedSection from "screens/Shared/AnimationSection";
import { animationStyle, theme } from "utils/styles";
import bookImage from "../../assets/images/book1.png";
import authorImage from "../../assets/images/gregImage.png";
import aboutImage2 from "../../assets/images/gregImage2.jpg";
import ourHistoryImage from "../../assets/images/ourHistoryImage.jpg";
import ourMissionImage from "../../assets/images/ourMissionImage.jpg";
import aboutStyle from "./About.styles";

const animation: any = makeStyles(animationStyle);

const About = () => {
  const classes = aboutStyle;
  const animationClasses = animation();
  const { t } = useTranslation();
  const [readMore, setReadMore] = useState<boolean>(false);

  const getHeader = () => {
    return (
      <Box
        sx={{
          background: "#2f3749",
        }}
      >
        <Box sx={classes.backImg}>
          <Box sx={classes.innerBackground}>
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
              maxHeight={"62vh"}
              spacing={2}
              className={animationClasses.fadeInDown}
            >
              <Typography sx={classes.headerText}>{t("about")}</Typography>
              <Typography sx={classes.subHeaderText}>
                {t("aboutHeader")}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
    );
  };

  const getOurMissionSection = () => {
    return (
      <Box sx={classes.darkBackground}>
        <Container maxWidth="xl" sx={{ zIndex: 1 }}>
          <Stack
            direction={"column"}
            spacing={2}
            pb={{ md: 15, xs: 10 }}
            pt={{ md: 10, xs: 10 }}
            alignItems={"center"}
          >
            <Typography sx={classes.gradientText} pb={3}>
              {t("missionVisionHistory")}
            </Typography>
            <Typography
              sx={classes.contentHeaderText}
              className={animationClasses.fadeInDown}
            >
              {t("missionVisionHistoryHeader")}
            </Typography>
            <Stack direction={"column"} spacing={{ md: 5, xs: 1 }}>
              <AnimatedSection animationType="zoomOut">
                <Box
                  sx={{
                    ...classes.pointsWrapper,
                    right: "100px",
                    [theme.breakpoints.down(1235)]: {
                      right: "0px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      ...classes.smallDecoration,
                      ...classes.leftRadiusBox,
                      background: "#ffa0cf",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      ...classes.missionWrapper,
                      ...classes.noRightRadiusBox,
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={7}
                        xl={7}
                        alignContent={"center"}
                      >
                        <Typography
                          sx={{ ...classes.titleText, color: "#ffa0cf" }}
                        >
                          {t("ourMission")}
                        </Typography>
                        <Typography sx={classes.descriptionText}>
                          {t("ourMissionDesc")}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={5}
                        xl={5}
                        textAlign={"center"}
                      >
                        <Box
                          component="img"
                          src={ourMissionImage}
                          alt={"icon"}
                          sx={classes.pointsImage}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      ...classes.largeDecoration,
                      ...classes.rightRadiusBox,
                      background: "#ffa0cf",
                    }}
                  ></Box>
                </Box>
              </AnimatedSection>
              <AnimatedSection animationType="zoomOut">
                <Box
                  sx={{
                    ...classes.pointsWrapper,
                    left: "100px",
                    [theme.breakpoints.down(1235)]: {
                      left: "0px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      ...classes.largeDecoration,
                      ...classes.leftRadiusBox,
                      background: "#eca9ff",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      ...classes.missionWrapper,
                      ...classes.noLeftRadiusBox,
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={5}
                        xl={5}
                        textAlign={"center"}
                      >
                        <Box
                          component="img"
                          src={aboutImage2}
                          alt={"icon"}
                          sx={classes.pointsImage}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={7}
                        xl={7}
                        alignContent={"center"}
                      >
                        <Typography
                          sx={{ ...classes.titleText, color: "#eca9ff" }}
                        >
                          {t("ourVision")}
                        </Typography>
                        <Typography sx={classes.descriptionText}>
                          {t("ourVisionDesc")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      ...classes.smallDecoration,
                      ...classes.rightRadiusBox,
                      background: "#eca9ff",
                    }}
                  ></Box>
                </Box>
              </AnimatedSection>
              <AnimatedSection animationType="zoomOut">
                <Box
                  sx={{
                    ...classes.pointsWrapper,
                    right: "100px",
                    [theme.breakpoints.down(1235)]: {
                      right: "0px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      ...classes.smallDecoration,
                      ...classes.leftRadiusBox,
                      background: "#9eb1ff",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      ...classes.missionWrapper,
                      ...classes.noRightRadiusBox,
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={7}
                        xl={7}
                        alignContent={"center"}
                      >
                        <Typography
                          sx={{ ...classes.titleText, color: "#9eb1ff" }}
                        >
                          {t("ourHistory")}
                        </Typography>
                        <Typography sx={classes.descriptionText}>
                          {t("ourHistoryDesc")}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={5}
                        xl={5}
                        textAlign={"center"}
                      >
                        <Box
                          component="img"
                          src={ourHistoryImage}
                          alt={"icon"}
                          sx={classes.pointsImage}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      ...classes.largeDecoration,
                      ...classes.rightRadiusBox,
                      background: "#9eb1ff",
                    }}
                  ></Box>
                </Box>
              </AnimatedSection>
            </Stack>
          </Stack>
        </Container>
      </Box>
    );
  };
  // const getOurMissionSection = () => {
  //   return (
  //     <Box sx={classes.darkBackground}>
  //       <Container maxWidth="xl" sx={{ zIndex: 1 }}>
  //         <Stack
  //           direction={"row"}
  //           spacing={2}
  //           pt={20}
  //           pb={10}
  //           pl={5}
  //           alignItems={"center"}
  //         >
  //           <Box sx={classes.image1Box}>
  //             <img src={aboutImage1} alt="back" style={classes.image1} />
  //           </Box>
  //           <Box sx={classes.image2Box}>
  //             <img src={aboutImage2} alt="back" style={classes.image2} />
  //           </Box>
  //           <Stack
  //             direction={"column"}
  //             spacing={5}
  //             px={10}
  //             sx={{ position: "relative", bottom: "25px" }}
  //           >
  //             <Stack direction={"column"} spacing={1.5}>
  //               <Typography sx={classes.titleText}>
  //                 {t("ourMission")}
  //               </Typography>
  //               <Typography sx={classes.descriptionText}>
  //                 {t("ourMissionDesc")}
  //               </Typography>
  //             </Stack>
  //             <Stack direction={"column"} spacing={1.5}>
  //               <Typography sx={classes.titleText}>{t("ourVision")}</Typography>
  //               <Typography sx={classes.descriptionText}>
  //                 {t("ourVisionDesc")}
  //               </Typography>
  //             </Stack>
  //             <Stack direction={"column"} spacing={1.5}>
  //               <Typography sx={classes.titleText}>
  //                 {t("ourHistory")}
  //               </Typography>
  //               <Typography sx={classes.descriptionText}>
  //                 {t("ourHistoryDesc")}
  //               </Typography>
  //             </Stack>
  //           </Stack>
  //           <Box>
  //             <img src={aboutImage3} alt="back" style={classes.image3} />
  //           </Box>
  //         </Stack>
  //       </Container>
  //     </Box>
  //   );
  // };

  const getAboutAuthor = () => {
    return (
      <Box sx={classes.authorBackground}>
        <Container maxWidth="xl" sx={{ zIndex: 1, height: "100%" }}>
          <Grid container height={"100%"}>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              py={{ md: 10, xs: 3 }}
            >
              <AnimatedSection animationType="fadeInLeft">
                <Stack
                  direction={"column"}
                  spacing={2}
                  height={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <AnimatedSection animationType="textWave">
                    <Typography sx={classes.headerText}>AKARI</Typography>
                    <Typography
                      sx={{ ...classes.headerText, marginTop: "-20px" }}
                    >
                      ARYACA
                    </Typography>
                  </AnimatedSection>
                  <Typography sx={classes.aboutAuthorText}>
                    {t("aboutAuthor1")}
                  </Typography>
                  <Typography sx={classes.aboutAuthorText}>
                    {t("aboutAuthor2")}
                  </Typography>
                  <Typography sx={classes.aboutAuthorText}>
                    {t("aboutAuthor3")}
                  </Typography>
                </Stack>
              </AnimatedSection>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              alignContent={"center"}
              alignSelf={"end"}
              textAlign={{ md: "end", xs: "center" }}
            >
              <AnimatedSection animationType="zoomIn">
                <Box
                  component="img"
                  src={authorImage}
                  alt={"authorImage"}
                  sx={classes.authorImage}
                />
              </AnimatedSection>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  const getAboutBook = () => {
    return (
      <Box sx={classes.darkBackground} py={5}>
        <Container maxWidth="xl" sx={{ zIndex: 1, height: "100%" }}>
          <Grid container height={"100%"} spacing={4}>
            <Grid
              item
              xl={5}
              lg={5}
              md={5}
              sm={12}
              xs={12}
              alignSelf={"start"}
              textAlign={{ md: "start", xs: "center" }}
            >
              <AnimatedSection animationType="fadeInDown">
                <Box
                  component="img"
                  src={bookImage}
                  alt={"bookImage"}
                  sx={classes.authorImage}
                />
              </AnimatedSection>
            </Grid>
            <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
              <Stack
                direction={"column"}
                spacing={2}
                height={"100%"}
                justifyContent={"center"}
              >
                <AnimatedSection animationType="fadeInDown">
                  <Typography sx={classes.bookNameText}>
                    {t("bookName")}
                  </Typography>
                </AnimatedSection>
                <AnimatedSection animationType="fadeInDown">
                  <Stack
                    direction={"column"}
                    spacing={2}
                    sx={{
                      maxHeight: "60vh",
                      overflow: "auto",
                    }}
                  >
                    <Typography sx={classes.aboutBookText}>
                      {t("aboutBook1")}
                    </Typography>
                    <Collapse in={readMore} timeout={500} unmountOnExit>
                      <Stack direction={"column"} spacing={2}>
                        <Typography sx={classes.aboutBookText}>
                          {t("aboutBook2")}
                        </Typography>
                        <Typography sx={classes.aboutBookText}>
                          {t("aboutBook3")}
                        </Typography>
                        <Typography sx={classes.aboutBookText}>
                          {t("aboutBook4")}
                        </Typography>
                        <Typography sx={classes.aboutBookText}>
                          {t("aboutBook5")}
                        </Typography>
                      </Stack>
                    </Collapse>
                  </Stack>
                </AnimatedSection>
                <AnimatedSection animationType="fadeInDown">
                  <Typography
                    sx={classes.readMoreText}
                    onClick={() => setReadMore(!readMore)}
                  >
                    {!readMore ? "Read More..." : "Read Less..."}
                  </Typography>
                </AnimatedSection>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  const getAbout = () => {
    return (
      <>
        {getHeader()}
        {getOurMissionSection()}
        {getAboutAuthor()}
        {getAboutBook()}
      </>
    );
  };

  return getAbout();
};

export default About;
