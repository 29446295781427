import { Box } from "@mui/material";
import strings from "global/constants/StringConstants";
import viewpaths from "global/constants/ViewPathConstants";
import { PhoneNumberUtil } from "google-libphonenumber";
import MomentHelpers from "helpers/MomentHelpers";
import { Error, FormattedResources, Resources } from "models/interfaces";
import moment from "moment-timezone";
import { logOutAction } from "redux/authSlice";
import { globalEmitter } from "utils/emitter";
import history from "utils/history";
import { store } from "utils/store";

const methodsContext = this;

export const isTruthy = (value: any, shouldCheckByType: boolean = true) => {
  const validatedByType = shouldCheckByType
    ? customValidatorByType(value)
    : true;

  if (value !== null && value !== undefined && validatedByType) {
    return true;
  }
  return false;
};

const customValidatorByType = (value: any) => {
  if (value !== undefined && value !== null) {
    const type = typeof value;
    switch (type) {
      case "string":
        return value.trim() !== "";
      case "object":
        if (Array.isArray(value)) {
          return value.length > 0;
        } else {
          return Object.keys(value).length > 0;
        }
      default:
        return true;
    }
  }
};

export const compareStrings = (string1: string, string2: string) => {
  if (!(isTruthy(string1) || isTruthy(string2))) {
    return true;
  } else {
    if (string1 && string2) {
      if (string1.toLowerCase() === string2.toLowerCase()) {
        return true;
      }
    }
  }
  return false;
};

export const getGreetings = () => {
  const date = new Date();
  let hours = date.getHours();
  let status =
    hours < 12
      ? "Good Morning"
      : hours <= 18 && hours >= 12
      ? "Good Afternoon"
      : "Good Evening";

  return status;
};

export const openInfoNotification = (message: any, title: string = "Info") => {
  globalEmitter.emit(strings.notification, {
    type: strings.info,
    message: message,
    title: title,
  });
};

export const openSuccessNotification = (
  message: any,
  title: string = "Success"
) => {
  globalEmitter.emit(strings.notification, {
    type: strings.success,
    message: message,
    title: title,
  });
};

export const openWarningNotification = (
  message: any,
  title: string = "Warning"
) => {
  globalEmitter.emit(strings.notification, {
    type: strings.warning,
    message: message,
    title: title,
  });
};

export const handleErrorMessage = (error: Error) => {
  if (error.statusCode) {
    switch (error.statusCode) {
      case 400:
        return openErrorNotification("Please check your request and try again");
      case 401:
        store.dispatch(logOutAction({}));
        history.push(viewpaths.landingViewPath);
        return null;
      case 403:
        return openErrorNotification(
          "You are not authorized to access this resource."
        );
      case 404:
        return openErrorNotification(
          "The requested resource could not be found."
        );
      case 500:
        return openErrorNotification(
          "There was an error on the server side. Please try again later."
        );
      case 406:
        return openErrorNotification(error.message);
      default:
        break;
    }
  } else if (
    error.errorMessage === "Failed to fetch" &&
    error.name === "TypeError"
  ) {
    return openErrorNotification(
      "This is a critical error that requires immediate attention from the support team. Please contact them."
    );
  }
};

export const openErrorNotification = (
  message: any,
  title: string = "Error"
) => {
  globalEmitter.emit(strings.notification, {
    type: strings.error,
    message: message,
    title: title,
  });
};

export function debounce(func: Function, wait: number) {
  let timeout: any;
  return function (...args: any) {
    const context = methodsContext;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export function debounceEventHandler(func: Function, wait: number) {
  const debounced = debounce(func, wait);
  return function (event: any) {
    event.persist();
    return debounced(event);
  };
}

export const getFormattedNumbers = (value: string) => {
  const matches = value.match(/\d+/g);
  let number = "";
  if (matches !== null) {
    matches.forEach((match) => {
      number += match;
    });
  }
  if (number.length === 10) {
    value = number.replace(/^(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  return { number: number, maskedNumber: value };
};

export const getFileExtension = (fileName: string) => {
  const ext = /^.+\.([^.]+)$/.exec(fileName);
  return ext === null ? "" : ext[1];
};

export const validatePhoneNumber = (mobile: string) => {
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  return re.test(mobile);
};

export const isPhoneValid = (phone: string) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error: any) {
    return false;
  }
};

export const tableRowRightAlign = (value: any) => {
  return (
    <Box component="span" sx={{ textAlign: "right", display: "block" }}>
      {value}
    </Box>
  );
};

const doesResourcesExistInLocalStorage = (resources: Resources[]) => {
  if (!isTruthy(resources)) {
    store.dispatch(logOutAction({}));
    history.push(viewpaths.landingViewPath);
    return false;
  }
  return true;
};

export const convertResourceToObjectFormat = (resources: Resources[]) => {
  if (!doesResourcesExistInLocalStorage(resources)) {
    return {} as FormattedResources;
  }
  let returnObject = {} as { [key: string]: string[] };
  resources.forEach((resource) => {
    returnObject[resource.path] = resource.permissions;
  });
  return returnObject;
};

export const convertESTtoUserLocalTime = (inputTime: string) => {
  const userTimeZone = MomentHelpers.guessTheTimeZone();
  const inputTimezone = "America/New_York";
  const inputTimeFormat = "MM-DD-YYYY HH:mm:ss";
  const outputTimeFormat = "hh:mm a";
  return moment
    .tz(inputTime, inputTimeFormat, inputTimezone)
    .tz(userTimeZone)
    .format(inputTimeFormat);
};

export function isValidEmail(email: string = "") {
  return strings.EmailRegex.test(email);
}

export function isPasswordStrong(password: string = "") {
  return strings.PasswordRegex.test(password);
}

export function isValidOTP(otp: string = "", digits: number = 6) {
  return otp.length === digits;
}

export function checkPasswordStrength(password: string = "") {
  let percentage = 0;
  // Check minimum length
  if (password.length >= 8) {
    percentage += 25;
  }
  // Check for at least one lowercase letter
  if (/[a-z]/.test(password)) {
    percentage += 25;
  }
  // Check for at least one number
  if (/\d/.test(password)) {
    percentage += 25;
  }
  // Check for at least one uppercase letter
  if (/[A-Z]/.test(password)) {
    percentage += 25;
  }
  // Determine strength based on percentage
  const strength = percentage === 100 ? "Strong" : "Weak";
  return { strength, percentage };
}

export function formatDateInLongDateFormat(date: Date | string) {
  return moment(date).format("LL");
}

export function formatDateWithTimeInLongDateFormat(date: Date | string) {
  return moment(date).format("LLL");
}

export const getMonthName = (monthNumber: number) => {
  const monthMoment = moment().month(monthNumber);
  return monthMoment.format("MMM");
};

export const getDayName = (dayNumber: number) => {
  const dayMoment = moment().day(dayNumber);
  return dayMoment.format("ddd");
};

export const capitalizeWords = (str: string) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export function mergeRefs<T = any>(...refs: React.Ref<T>[]) {
  return (element: T) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(element);
      } else if (ref) {
        (ref as React.MutableRefObject<T | null>).current = element;
      }
    });
  };
}
