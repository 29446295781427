import HelpIcon from "@mui/icons-material/Help";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CustomIcon, CustomInput } from "global/components";
import CustomButton from "global/components/CustomButton/CustomButton";
import CustomSelect from "global/components/CustomSelect/CustomSelect";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import rightIcon from "../../assets/icons/Group (1).svg";
import leftIcon from "../../assets/icons/Group.svg";
import noImage from "../../assets/images/noImage1.png";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import {
  handleErrorMessage,
  isTruthy,
  openSuccessNotification,
} from "../../helpers/methods";
import { animationStyle, theme } from "../../utils/styles";
import Firework from "./Firework";
import FootballStyle from "./Football.styles";
import FootballDialog from "./FootballDialog";
import FootballGraph from "./FootballGraph";
import FootballGround from "./FootballGround";
import { footballPrediction } from "./FootballService";
import {
  days,
  formData,
  formValidation,
  hours,
  minutesSeconds,
  years,
} from "./FootballValidation";

declare const google: any;

const animationStyle1 = makeStyles(animationStyle);

const Football = () => {
  const classes = FootballStyle;
  const animationClasses = animationStyle1();
  const { t } = useTranslation();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [address, setAddress] = useState<any>({
    latitude: "",
    longitude: "",
    location: "",
  });
  const [formFields, setFormFields] = useState(formData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [display, setDisplay] = useState<boolean>(false);
  const [goldenFoot, setGoldenFoot] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [animation, setAnimation] = useState<boolean>(false);
  const [graphData, setGraphData] = useState<any>();
  const [boxOpacity, setBoxOpacity] = useState(1);
  const [positionPercentage, setPositionPercentage] = useState<any>({});
  const [category, setCategory] = useState<any>({
    firstChoiceName: "",
    firstChoiceColor: "",
    secondChoiceName: "",
    secondChoiceColor: "",
    side: "",
    footballerChance: "",
  });
  const emptyGraphData: any = {
    Defender: "32.15",
    Forward: "14.02",
    Goalkeeper: "10.00",
    Midfielder: "26.13",
    Striker: "17.70",
  };

  const yearMenu = [
    { value: 0, content: `${t("selectYear")}`, disabled: true },
    ...years,
  ];

  const monthMenu = [
    { value: 0, content: `${t("selectMonth")}`, disabled: true },
    { content: `${t("January")}`, value: 1 },
    { content: `${t("February")}`, value: 2 },
    { content: `${t("March")}`, value: 3 },
    { content: `${t("April")}`, value: 4 },
    { content: `${t("May")}`, value: 5 },
    { content: `${t("June")}`, value: 6 },
    { content: `${t("July")}`, value: 7 },
    { content: `${t("August")}`, value: 8 },
    { content: `${t("September")}`, value: 9 },
    { content: `${t("October")}`, value: 10 },
    { content: `${t("November")}`, value: 11 },
    { content: `${t("December")}`, value: 12 },
  ];

  const dateMenu = [
    { value: 0, content: `${t("selectDate")}`, disabled: true },
    ...days,
  ];

  const hoursMenu = [
    { value: -1, content: `${t("selectHour")}`, disabled: true },
    ...hours,
  ];

  const minutesMenu = [
    { value: -1, content: `${t("selectMinute")}`, disabled: true },
    ...minutesSeconds,
  ];

  const secondsMenu = [
    { value: -1, content: `${t("selectSecond")}`, disabled: true },
    ...minutesSeconds,
  ];

  useEffect(() => {
    if (display) {
      const timer = setTimeout(() => {
        setDisplay(false);
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [display]);

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    address.location &&
      setFormFields({
        ...formFields,
        birthPlace: {
          ...formFields.birthPlace,
          value: address.location,
        },
      });
    !formFields.birthPlace.value &&
      setAddress({
        ...address,
        location: "",
      });
  }, [address.location]);

  const handlePlaceChanged = async (autocomplete: any) => {
    const place = autocomplete.getPlace();
    const { geometry } = place;
    if (geometry && geometry.location) {
      const latitude = geometry.location.lat();
      const longitude = geometry.location.lng();
      setAddress({
        ...address,
        latitude: latitude,
        longitude: longitude,
        location: place.formatted_address,
      });
    }
  };

  const initialize = () => {
    const input: any = document.getElementById("birthPlace");
    const autocomplete: any = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(autocomplete, "place_changed", () =>
      handlePlaceChanged(autocomplete)
    );
  };

  const handleValidation = () => {
    const { isValid, errors } = formValidation(formFields, address);
    setFormFields({ ...errors });
    return isValid;
  };

  const handlePredict = async () => {
    const birth_date = `${formFields.year.value}-${moment(
      formFields.month.value,
      "M"
    ).format("MM")}-${moment(formFields.date.value, "D").format("DD")} ${moment(
      formFields.hours.value,
      "H"
    ).format("HH")}:${moment(formFields.minutes.value, "m").format(
      "mm"
    )}:${moment(formFields.seconds.value, "s").format("ss")}`;

    try {
      if (handleValidation()) {
        const profileData = {
          name: formFields.firstName.value.trim(),
          birth_date: birth_date,
          longitude: `${address.longitude}`,
          latitude: `${address.latitude}`,
          altitude: "0",
          blood_group: formFields.bloodGroup.value,
          timezone: null,
          isVedic: "1",
        };
        setAnimation(false);
        setIsLoading(true);
        const response = await footballPrediction(profileData);
        setPositionPercentage(response.positionPercentage);
        setGraphData(response.percentage);
        setCategory({
          firstChoiceName: response.firstChoiceName,
          firstChoiceColor: response.firstChoiceColor,
          secondChoiceName: response.secondChoiceName,
          secondChoiceColor: response.secondChoiceColor,
          side: response.side,
          footballerChance: response.footballerChance,
        });
        setGoldenFoot(response.goldenFoot);
        if (response.goldenFoot) {
          setDisplay(true);
          openSuccessNotification(`${t("goldenFootLabel")}`);
        }
        setAnimation(true);
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      handleErrorMessage(error);
    }
  };

  const handleFieldChange = (event: any) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
        error: "",
      },
    });
    if (event.target.name === "birthPlace") {
      setAddress({
        latitude: "",
        longitude: "",
        location: "",
      });
    }
  };

  const getHeader = () => {
    return (
      <Box
        sx={{
          background: "#2f3749",
        }}
      >
        <Box sx={classes.backImg}>
          <Box sx={classes.innerBackground}>
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
              maxHeight={"55vh"}
              spacing={2}
              className={animationClasses.fadeInDown}
            >
              <Typography sx={classes.headerText}>
                {t("footballHeader")}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
    );
  };

  const getFootballFormComponent = () => {
    return (
      <Box
        sx={{
          background:
            "linear-gradient(90deg, #4d3e5d 0%, #443f64 50%, #3b4564 100%) !important",
          borderRadius: "6px",
          position: "relative",
          px: 4,
          py: 2,
        }}
        className={
          animation ? animationClasses.fadeInDown : animationClasses.fadeInUp
        }
      >
        <Typography sx={classes.gradientText} pb={2}>
          {t("playerDetails")}
        </Typography>
        <Grid container spacing={1} justifyContent={"center"} rowGap={"10px"}>
          <Grid item xl={4.8} lg={4.8} md={4.8} sm={12} xs={12}>
            <CustomInput
              required
              label={t("name")}
              name="firstName"
              value={formFields.firstName.value}
              onChange={handleFieldChange}
              placeHolder={t("namePlaceholder")}
              error={
                !isTruthy(formFields.firstName.value) &&
                formFields.firstName.error
              }
            />
          </Grid>
          <Grid item xl={2.4} lg={2.4} md={2.4} sm={12} xs={12}>
            <CustomSelect
              required
              label={t("birthDate")}
              menuItems={yearMenu}
              onChange={handleFieldChange}
              id={"year"}
              name={"year"}
              value={formFields.year.value}
              error={formFields.year.error}
            />
          </Grid>
          <Grid
            item
            xl={2.4}
            lg={2.4}
            md={2.4}
            sm={12}
            xs={12}
            mt={{ md: 4, xs: 0 }}
          >
            <CustomSelect
              label=""
              menuItems={monthMenu}
              onChange={handleFieldChange}
              id={"month"}
              name={"month"}
              value={formFields.month.value}
              error={formFields.month.error}
            />
          </Grid>
          <Grid
            item
            xl={2.4}
            lg={2.4}
            md={2.4}
            sm={12}
            xs={12}
            mt={{ md: 4, xs: 0 }}
          >
            <CustomSelect
              menuItems={dateMenu}
              onChange={handleFieldChange}
              id={"date"}
              name={"date"}
              value={formFields.date.value}
              error={formFields.date.error}
            />
          </Grid>
          <Grid item xl={2.4} lg={2.4} md={2.4} sm={12} xs={12}>
            <CustomSelect
              required
              label={t("birthTime")}
              menuItems={hoursMenu}
              onChange={handleFieldChange}
              id={"hours"}
              name={"hours"}
              value={formFields.hours.value}
              error={formFields.hours.error}
            />
          </Grid>
          <Grid
            item
            xl={2.4}
            lg={2.4}
            md={2.4}
            sm={12}
            xs={12}
            mt={{ md: 4, xs: 0 }}
          >
            <CustomSelect
              menuItems={minutesMenu}
              onChange={handleFieldChange}
              id={"minutes"}
              name={"minutes"}
              value={formFields.minutes.value}
              error={formFields.minutes.error}
            />
          </Grid>
          <Grid
            item
            xl={2.4}
            lg={2.4}
            md={2.4}
            sm={12}
            xs={12}
            mt={{ md: 4, xs: 0 }}
          >
            <CustomSelect
              menuItems={secondsMenu}
              onChange={handleFieldChange}
              id={"seconds"}
              name={"seconds"}
              value={formFields.seconds.value}
              error={formFields.seconds.error}
            />
          </Grid>
          <Grid item xl={4.8} lg={4.8} md={4.8} sm={12} xs={12}>
            <CustomInput
              required
              label={t("birthPlace")}
              placeHolder={t("birthPlacePlaceholder")}
              id="birthPlace"
              type="birthPlace"
              name="birthPlace"
              value={formFields.birthPlace.value}
              onChange={handleFieldChange}
              error={
                !isTruthy(
                  formFields.birthPlace.value &&
                    address.latitude &&
                    address.longitude
                ) && formFields.birthPlace.error
              }
            />
          </Grid>
          <Grid
            item
            xl={2}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            display={"flex"}
            justifyContent={"center"}
          >
            <CustomButton
              label={t("predict")}
              buttonType="gradientBtn"
              onClick={handlePredict}
              customClasses={classes.contactBtn}
            />
          </Grid>
        </Grid>
        <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent={"space-between"}
          alignItems={"center"}
          pt={2}
        >
          <Typography sx={classes.noteText}>* {t("footballNote")}</Typography>
          <Stack
            direction={"row"}
            sx={{ cursor: "pointer", paddingY: "8px" }}
            spacing={1}
            onClick={() => setIsOpen(true)}
          >
            <Typography sx={classes.nameField}>{t("howItWorks")}</Typography>
            <CustomIcon icon={<HelpIcon htmlColor="#E5B80B" />} />
          </Stack>
        </Stack>
      </Box>
    );
  };

  const getFootballGraphComponent = () => {
    return (
      <Box
        sx={{
          background:
            "linear-gradient(90deg, #4d3e5d 0%, #443f64 50%, #3b4564 100%) !important",
          borderRadius: "6px",
          position: "relative",
          height: "100%",
        }}
        className={animation ? animationClasses.fadeInLeft : ""}
      >
        {graphData ? (
          <FootballGraph
            percentages={graphData}
            needColor={true}
            footballerChance={category.footballerChance}
          />
        ) : (
          <FootballGraph percentages={emptyGraphData} needColor={false} />
        )}
      </Box>
    );
  };

  const getFootballGroundComponent = () => {
    return graphData && positionPercentage ? (
      <Box
        sx={{ height: "100%" }}
        className={animation ? animationClasses.fadeInRight : ""}
      >
        <FootballGround
          category={category}
          goldenFoot={goldenFoot}
          percentages={positionPercentage}
        />
      </Box>
    ) : (
      <Box
        component="img"
        src={noImage}
        alt={"Image not found"}
        sx={{
          borderRadius: "6px",
          width: "100%",
          height: "100%",
        }}
      />
    );
  };

  const getContent = () => {
    return (
      <Box py={10} sx={classes.darkBackground}>
        <Container maxWidth="lg">
          {getFootballFormComponent()}
          <Box pt={4}>
            {category.firstChoiceName && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  pb: 3,
                }}
                className={animation ? animationClasses.zoomIn : ""}
              >
                <Box
                  component="img"
                  src={leftIcon}
                  alt={"decoration"}
                  sx={{
                    width: "100%",
                    [theme.breakpoints.down("sm")]: {
                      width: "18vw",
                    },
                  }}
                />
                <Typography
                  sx={{
                    ...classes.gradientText,
                    margin: "0px",
                    textWrap: "nowrap",
                  }}
                  px={2}
                >
                  {t(`${category.side}`)} {t(`${category.firstChoiceName}`)}
                </Typography>
                <Box
                  component="img"
                  src={rightIcon}
                  alt={"decoration"}
                  sx={{
                    width: "100%",
                    [theme.breakpoints.down("sm")]: {
                      width: "18vw",
                    },
                  }}
                />
              </Box>
            )}
            <Grid container spacing={4}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                {getFootballGraphComponent()}
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                {getFootballGroundComponent()}
              </Grid>
            </Grid>
            {goldenFoot && (
              <Typography sx={classes.gradientText} pt={4}>
                {t("goldenFootLabel")}
              </Typography>
            )}
          </Box>
        </Container>
      </Box>
    );
  };

  const getFootball = () => {
    return (
      <>
        {display && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 200,
            }}
          >
            <Firework />
          </Box>
        )}
        {getHeader()}

        {getContent()}
        <FootballDialog isOpen={isOpen} setIsOpen={setIsOpen} />
        <CustomLoader isLoading={isLoading} />
      </>
    );
  };

  return getFootball();
};

export default Football;
