import {
  centerItemFlex,
  customButtonStyle,
  getRelativeFontSize,
  primaryBorderColor,
  primaryColorBlack,
  primaryColorWhite,
  regularFont,
} from "../../../utils/styles";

const customButtonStyles = {
  btnStyle: {
    ...regularFont,
    ...customButtonStyle,
    ...centerItemFlex,
    minWidth: "fit-content",
    color: primaryColorBlack,
    borderColor: primaryBorderColor,
    "&": {
      height: "42px",
      boxShadow: "none",
    },
    alignItems: "center",
    textAlign: "center",
    border: "1px solid #000",
    borderRadius: "5px",
    width: "fit-content",
    padding: "8px 10px",
    cursor: "pointer",
    "&:hover .animateY1": {
      transform: "translateY(-100%) rotateY(10deg)",
      opacity: 0,
    },
    "&:hover .animateY2": {
      transform: "translateY(0) rotateY(0deg)",
      opacity: 1,
    },
  },
  btnInnerBox: {
    position: "relative",
    overflow: "hidden",
    fontSize: "24px",
    fontWeight: "bold",
  },
  // Existing styles
  mainText: {
    display: "block",
    transition: "transform 0.37s ease, opacity 0.37s ease",
    fontSize: getRelativeFontSize(3),
  },
  mainText1: {
    background: "transparent", // Ensure the background is transparent
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: getRelativeFontSize(4),
  },
  gradientText: {
    background:
      "linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  subText: {
    display: "block",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    opacity: 0,
    transform: "translateY(100%) rotateY(-10deg)",
    transition: "transform 0.37s ease, opacity 0.37s ease",
    fontSize: getRelativeFontSize(3),
  },
  subText1: {
    background: "transparent", // Ensure the background is transparent
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: getRelativeFontSize(4),
  },
  loading: {
    width: "100%",
    ...centerItemFlex,
  },
  transparentBtn: {
    background: "transparent",
    border: "none",
    color: primaryColorWhite,
    "&:hover": {
      background: "transparent !important",
    },
  },
  borderedBtn: {
    background: "transparent",
    border: "1px solid #ffffff",
    color: primaryColorWhite,
    "&:hover": {
      background: "transparent !important",
    },
  },
  gradientBtn: {
    color: primaryColorWhite,
    backgroundImage:
      "linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%) !important",
  },
  secondaryBtn: {
    background: primaryColorWhite,
    color: "#828282",
    "&:hover": {
      background: primaryColorWhite,
    },
  },
} as const;

export default customButtonStyles;
