import { motion, useInView } from "framer-motion";
import React, { useRef } from "react";

interface AnimatedSectionProps {
  animationType:
    | "fade"
    | "fadeInDown"
    | "fadeInUp"
    | "fadeInLeft"
    | "fadeInRight"
    | "zoomIn"
    | "zoomOut"
    | "bounce"
    | "typewriter"
    | "textWave";
  children: React.ReactNode;
  [key: string]: any;
}

const AnimatedSection: React.FC<AnimatedSectionProps> = ({
  animationType,
  children,
  ...props
}) => {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });

  const animations = {
    fade: {
      opacity: inView ? 1 : 0,
      transition: { duration: 2 },
    },
    fadeInDown: {
      opacity: inView ? 1 : 0,
      y: inView ? 0 : -50,
      transition: { duration: 1 },
    },
    fadeInUp: {
      opacity: inView ? 1 : 0,
      y: inView ? 0 : 50,
      transition: { duration: 1 },
    },
    fadeInLeft: {
      opacity: inView ? 1 : 0,
      x: inView ? 0 : -50,
      transition: { duration: 1 },
    },
    fadeInRight: {
      opacity: inView ? 1 : 0,
      x: inView ? 0 : 50,
      transition: { duration: 1 },
    },
    zoomIn: { scale: inView ? 1 : 0.5, transition: { duration: 1 } },
    zoomOut: { scale: inView ? 1 : 1.2, transition: { duration: 1 } },
    bounce: {
      scale: inView ? [1, 1.2, 0.9, 1.05, 1] : 1,
      transition: { duration: 1, type: "spring", stiffness: 500, damping: 20 },
    },
    typewriter: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
        delayChildren: 0.3,
      },
      overflow: "hidden",
      whiteSpace: "nowrap",
      borderRight: "2px solid #ffffff",
      width: inView ? "100%" : "0",
    },
    textWave: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateY(0%)" : "translateY(50%)",
      transition: {
        duration: 1.5,
        ease: "easeInOut",
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  };

  const typewriterChildren = (text: React.ReactNode) => {
    if (typeof text === "string") {
      return text.split("").map((char, index) => (
        <motion.span
          key={index}
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: inView ? 1 : 0, width: inView ? "auto" : 0 }}
          transition={{ delay: index * 0.05 }}
        >
          {char}
        </motion.span>
      ));
    }
    return text;
  };

  return (
    <motion.div
      ref={ref}
      animate={
        animationType === "typewriter"
          ? animations["typewriter"]
          : animations[animationType] || {}
      }
      {...props}
    >
      {animationType === "typewriter" ? typewriterChildren(children) : children}
    </motion.div>
  );
};

export default AnimatedSection;
