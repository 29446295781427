import urls from "../../global/constants/UrlConstants";
import { getCallParams, makeCall } from "../../utils/service";

export const footballPrediction = async (profile: any) => {
  const body = {
    first_person: profile,
  };
  try {
    const callParams = await getCallParams("POST", body);
    const response = await makeCall(urls.footballPrediction, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
