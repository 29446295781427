import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import AboutStudioSection from "./components/AboutStudioSection";
import AchievementsSection from "./components/AchievementsSection";
import ContactUsSection from "./components/ContactUsSection";
import HeroSection from "./components/HeroSection";
import OurServicesSection from "./components/OurServicesSection";
import TestimonialsSection from "./components/TestimonialsSection";
import WhyChooseUsSection from "./components/WhyChooseUsSection";

const Home = () => {
  const section1Ref = useRef<HTMLElement>(null);
  const section2Ref = useRef<HTMLElement>(null);
  const section3Ref = useRef<HTMLElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const offsetRef = useRef(0);
  const speed = 0.05;

  useEffect(() => {
    const handleSmoothScroll = () => {
      if (scrollContainerRef.current) {
        const height =
          scrollContainerRef.current.getBoundingClientRect().height - 1;
        document.body.style.height = `${Math.floor(height)}px`;

        const scrollLoop = () => {
          offsetRef.current += (window.pageYOffset - offsetRef.current) * speed;
          if (scrollContainerRef.current) {
            scrollContainerRef.current.style.transform = `translateY(-${offsetRef.current}px) translateZ(0)`;
          }
          requestAnimationFrame(scrollLoop);
        };

        scrollLoop();
      }
    };

    handleSmoothScroll();
  }, []);

  return (
    <Box
      // ref={scrollContainerRef}
      sx={{
        background:
          "-webkit-linear-gradient(top, rgb(56, 64, 83) 0%, rgb(47, 55, 73) 100%)",
      }}
    >
      <HeroSection
        section1Ref={section1Ref}
        section2Ref={section2Ref}
        section3Ref={section3Ref}
      />
      <AboutStudioSection section2Ref={section2Ref} />
      <AchievementsSection />
      <OurServicesSection section3Ref={section3Ref} />
      <WhyChooseUsSection />
      {/* <TrustedBySection /> */}
      <TestimonialsSection />
      <ContactUsSection />
    </Box>
  );
};

export default Home;
