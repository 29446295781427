import { Box, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { primaryColorBlack } from "../../../utils/styles";
import customButtonStyles from "./CustomButton.styles";

interface CustomProps {
  label: string;
  onClick: Function;
  variant?: "contained" | "outlined" | "text";
  loading?: boolean;
  customClasses?: any;
  buttonType?: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  disabled?: boolean;
}

const CustomButton = (props: CustomProps) => {
  const getCustomCss = () => {
    switch (props.buttonType) {
      case "transparentBtn":
        return classes.transparentBtn;
      case "gradientBtn":
        return classes.gradientBtn;
      case "transparentGradientBtn":
        return classes.transparentBtn;
      case "borderedBtn":
        return classes.borderedBtn;
      case "secondaryBtn":
        return classes.secondaryBtn;
      default:
        return {};
    }
  };

  const classes = customButtonStyles;
  const buttonType = getCustomCss();
  const appliedClass = {
    ...classes.btnStyle,
    ...buttonType,
    ...(props.customClasses ?? {}),
  };

  const processing = props.loading ? props.loading : false;

  return (
    <Button
      variant={props.variant}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      sx={appliedClass}
      onClick={(event) => props.onClick(event)}
      disabled={props.loading ?? props.disabled}
    >
      <Box sx={classes.btnInnerBox}>
        {processing ? (
          <Box sx={classes.loading}>
            <CircularProgress size={20} sx={{ color: primaryColorBlack }} />
          </Box>
        ) : (
          <>
            <Typography
              className="animateY1"
              sx={
                props.buttonType === "transparentGradientBtn"
                  ? [classes.mainText, classes.mainText1, classes.gradientText]
                  : classes.mainText
              }
            >
              {props.label}
            </Typography>
            <Typography
              className="animateY2"
              aria-hidden="true"
              sx={
                props.buttonType === "transparentGradientBtn"
                  ? [classes.subText, classes.subText1, classes.gradientText]
                  : classes.subText
              }
            >
              {props.label}
            </Typography>
          </>
        )}
      </Box>
    </Button>
  );
};

export default CustomButton;
