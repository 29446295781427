import { Box, Grid, Stack, Typography } from "@mui/material";
import CustomButton from "global/components/CustomButton/CustomButton";
import viewpaths from "global/constants/ViewPathConstants";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import AnimatedSection from "screens/Shared/AnimationSection";
import history from "utils/history";
import { primaryColorWhite } from "utils/styles";
import homeStyles from "../Home.styles";

const AchievementsSection = () => {
  const classes = homeStyles;
  const { t } = useTranslation();
  const sectionRef = useRef(null);
  //   const { inView, hasAnimated } = useInView(sectionRef, 0);

  return (
    <Box sx={classes.achievementsBackground} ref={sectionRef}>
      <Grid
        container
        spacing={{ md: 4, xs: 2 }}
        sx={{ zIndex: 1, px: 5, py: { md: 12, xs: 5 } }}
      >
        <Grid
          item
          xl={4}
          lg={4}
          md={12}
          sm={12}
          xs={12}
          alignContent={"center"}
        >
          <AnimatedSection animationType="fadeInLeft">
            <Stack direction={"column"} spacing={5}>
              <Typography
                sx={{ ...classes.contentHeaderText, textAlign: "start" }}
              >
                {t("achievementsHeader")}
              </Typography>
              <Typography
                sx={{ ...classes.subHeaderText, color: primaryColorWhite }}
              >
                {t("achievementsSubHeader")}
              </Typography>
              <CustomButton
                buttonType="borderedBtn"
                label={t("viewServices")}
                onClick={() => history.push(viewpaths.calculatorsViewPath)}
                customClasses={classes.viewServiceBtn}
              />
            </Stack>
          </AnimatedSection>
        </Grid>
        <Grid
          item
          xl={4}
          lg={4}
          md={12}
          sm={12}
          xs={12}
          alignContent={"center"}
        >
          <AnimatedSection animationType="fadeInLeft">
            <Stack direction={"column"} spacing={1} pb={{ md: 6, xs: 2 }}>
              <Typography sx={classes.headerText}>28%</Typography>
              <Typography sx={classes.subHeaderText}>
                {t("achievement1")}
              </Typography>
            </Stack>
          </AnimatedSection>
          <AnimatedSection animationType="fadeInLeft">
            <Stack direction={"column"} spacing={1} pb={{ md: 6, xs: 2 }}>
              <Typography sx={classes.headerText}>30+</Typography>
              <Typography sx={classes.subHeaderText}>
                {t("achievement2")}
              </Typography>
            </Stack>
          </AnimatedSection>
        </Grid>
        <Grid
          item
          xl={4}
          lg={4}
          md={12}
          sm={12}
          xs={12}
          alignContent={"center"}
        >
          <AnimatedSection animationType="fadeInLeft">
            <Stack direction={"column"} spacing={1} pb={{ md: 6, xs: 2 }}>
              <Typography sx={classes.headerText}>3.5k</Typography>
              <Typography sx={classes.subHeaderText}>
                {t("achievement3")}
              </Typography>
            </Stack>
          </AnimatedSection>
          <AnimatedSection animationType="fadeInLeft">
            <Stack direction={"column"} spacing={1} pb={{ md: 6, xs: 2 }}>
              <Typography sx={classes.headerText}>2x</Typography>
              <Typography sx={classes.subHeaderText}>
                {t("achievement4")}
              </Typography>
            </Stack>
          </AnimatedSection>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AchievementsSection;
