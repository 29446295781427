import content10 from "../../assets/images/content10.jpg";
import content2 from "../../assets/images/content2.jpg";
import content3 from "../../assets/images/content3.jpg";
import content4 from "../../assets/images/content4.jpg";
import content5 from "../../assets/images/content5.jpg";
import content6 from "../../assets/images/content6.jpg";
import content7 from "../../assets/images/content7.jpg";
import content8 from "../../assets/images/content8.jpg";
import content9 from "../../assets/images/content9.jpg";
import logo from "../../assets/images/smallLogo.png";

export const articles = [
  {
    image: content2,
    title:
      "Unlocking the Secrets of Epigenetics: How Environment Shapes Your Genes",
    description:
      "Discover how environmental factors like diet, stress, and lifestyle can alter gene expression, influencing health and behavior. This article delves into the science of epigenetics and its profound implications for personal well-being",
    authorImage: logo,
    author: "Epigenetic Calculators",
    date: "Sept 4, 2024",
    link: "",
  },
  {
    image: content3,
    title: "Astro-Epigenetics: Merging the Stars with Science",
    description:
      "Explore the innovative field of astro-epigenetics, where traditional astrological concepts meet modern genetic research. Learn how birth dates and planetary alignments could influence gene expression and personal development.",
    authorImage: logo,
    author: "Epigenetic Calculators",
    date: "Aug 29, 2024",
    link: "",
  },
  {
    image: content4,
    title: "The Role of Epigenetics in Personalized Medicine",
    description:
      "This article examines how epigenetic research is revolutionizing personalized medicine, enabling more accurate predictions and treatments based on individual genetic profiles.",
    authorImage: logo,
    author: "Epigenetic Calculators",
    date: "Aug 10, 2024",
    link: "",
  },
  {
    image: content5,
    title: "Epigenetic Modifications: The Key to Longevity and Health",
    description:
      "Learn about the different types of epigenetic modifications, such as DNA methylation, and their potential to extend lifespan and improve health outcomes by modulating gene expression.",
    authorImage: logo,
    author: "Epigenetic Calculators",
    date: "Jul 25, 2024",
    link: "",
  },
  {
    image: content6,
    title: "How Astro-Epigenetic Tools Enhance Career Success",
    description:
      "Discover how astro-epigenetic calculators can help match individuals to careers that align with their genetic predispositions and astrological profiles, leading to greater job satisfaction and success.",
    authorImage: logo,
    author: "Epigenetic Calculators",
    date: "Jul 8, 2024",
    link: "",
  },
];

export const publications = [
  {
    image: content7,
    title: "Epigenetic Mechanisms and Their Influence on Gene Expression",
    description:
      "This publication explores the foundational mechanisms of epigenetics, such as DNA methylation and histone modification, and their critical roles in regulating gene expression. It highlights recent advancements in understanding how these processes impact health and disease.",
    authorImage: logo,
    author: "Epigenetic Calculators",
    date: "Sept 4, 2024",
    link: "",
  },
  {
    image: content8,
    title: "Astro-Epigenetics: A Novel Approach to Personalized Medicine",
    description:
      "This groundbreaking study introduces the concept of astro-epigenetics, demonstrating how astrological data can be integrated with epigenetic profiles to create highly personalized healthcare strategies. The publication discusses case studies and potential applications in predictive medicine.",
    authorImage: logo,
    author: "Epigenetic Calculators",
    date: "Aug 29, 2024",
    link: "",
  },
  {
    image: content9,
    title: "Epigenetic Biomarkers: Applications in Health and Disease",
    description:
      "This publication reviews the current state of research on epigenetic biomarkers, focusing on their potential use in diagnosing diseases, predicting health outcomes, and tailoring therapeutic interventions. It provides a comprehensive overview of clinical applications and future directions.",
    authorImage: logo,
    author: "Epigenetic Calculators",
    date: "Aug 10, 2024",
    link: "",
  },
  {
    image: content10,
    title: "The Impact of Environmental Factors on Epigenetic Inheritance",
    description:
      "This research paper investigates how environmental exposures, such as nutrition and stress, can lead to epigenetic changes that are passed down through generations. It discusses the implications for public health and the prevention of hereditary diseases.",
    authorImage: logo,
    author: "Epigenetic Calculators",
    date: "Jul 25, 2024",
    link: "",
  },
];

export const color = [
  "#ff68cc",
  "#dc5fff",
  "#a080ff",
  "#7f99ff",
  "#ff80bf",
  "#c97eff",
  "#6f88ff",
];
