import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AnimatedSection from "screens/Shared/AnimationSection";
import { theme } from "utils/styles";
import insightsIcon from "../../../assets/icons/insightsIcon.svg";
import technologyIcon from "../../../assets/icons/technologyIcon.svg";
import userFriendlyIcon from "../../../assets/icons/userFriendlyIcon.svg";
import homeStyles from "../Home.styles";

const WhyChooseUsSection = () => {
  const classes = homeStyles;
  const { t } = useTranslation();

  const getIconBasedOnIndex = (index: number) => {
    switch (index) {
      case 1:
        return technologyIcon;
      case 2:
        return insightsIcon;
      case 3:
        return userFriendlyIcon;
    }
  };

  return (
    <Container maxWidth="lg" sx={{ pb: 15 }}>
      <AnimatedSection animationType="fadeInUp">
        <Grid container spacing={4}>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            alignContent={"center"}
          >
            <Typography
              sx={{ ...classes.contentHeaderText, textAlign: "start" }}
            >
              {t("whyChooseUs")}
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Typography sx={classes.subHeaderText}>
              {t("whyChooseUsDesc")}
            </Typography>
          </Grid>
        </Grid>
      </AnimatedSection>
      <AnimatedSection animationType="zoomIn">
        <Grid container spacing={4} mt={10}>
          {[1, 2, 3].map((index) => (
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} key={index}>
              <Box sx={classes.reasonBox}>
                <Box sx={classes.greyBox}>
                  <Stack direction={"column"} py={3} px={4} spacing={2}>
                    <Typography
                      sx={{
                        ...classes.gradientText,
                        fontSize: "14px",
                        [theme.breakpoints.down("md")]: {
                          fontSize: "14px",
                        },
                      }}
                    >
                      {t("reason")} {index}
                    </Typography>
                    <Box
                      component="img"
                      src={getIconBasedOnIndex(index)}
                      alt={"icon"}
                      sx={{
                        height: "30px",
                        width: "fit-content",
                        background: "#f2e4fb",
                        borderRadius: "50%",
                        padding: "20px",
                        alignSelf: "center",
                      }}
                    />
                    <Typography
                      sx={{ ...classes.calculatorHeader, textAlign: "center" }}
                    >
                      {t(`reason${index}`)}
                    </Typography>
                    <Typography
                      sx={{
                        ...classes.subHeaderText,
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                    >
                      {t(`reason${index}Desc`)}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </AnimatedSection>
    </Container>
  );
};

export default WhyChooseUsSection;
