import {
  getRelativeFontSize,
  mediumFont,
  primaryColorWhite,
  regularFont,
} from "../../../utils/styles";

const customInputStyles = {
  nameField: {
    ...mediumFont,
    color: primaryColorWhite,
    fontSize: getRelativeFontSize(4),
  },
  textField: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderRadius: "8px",
    },
    "& .MuiInputBase-input": {
      ...regularFont,
      fontSize: getRelativeFontSize(3),
      position: "relative",
      padding: "10px 13px",
      border: "2px solid transparent !important",
      background:
        "linear-gradient(to right, white, white), linear-gradient(45deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%) !important",
      opacity: 1,
      WebkitBackgroundClip: "padding-box, border-box !important",
      backgroundOrigin: "padding-box, border-box !important",
      borderRadius: "8px",
      color: "#222222",
      "&::placeholder": {
        ...regularFont,
        fontSize: getRelativeFontSize(3),
        color: "#C8C8C8",
      },
    },
    "& .MuiOutlinedInput-root": {
      ...regularFont,
      color: "#828282",
      borderRadius: "8px",
      border: "none",
      padding: "0px",
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  },
} as const;

export default customInputStyles;
