import { StringConstants } from "global/constants/StringConstants";

class NotificationConstants extends StringConstants {
  GENERIC_ERROR = "An error has occurred";
  REGISTER = "Registration completed successfully";
  UPDATE = "Updated successfully";
}

let notifiers = new NotificationConstants();
export default notifiers;
