import React, { useEffect, useRef } from "react";

const Firework: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const cw = window.innerWidth;
    const ch = window.innerHeight;
    let fireworks: Firework[] = [];
    let particles: Particle[] = [];
    const hue = 120;
    let limiterTick = 0;
    const limiterTotal = 20;
    let timerTick = 0;
    let timerTotal = 20;
    let mousedown = false;
    let mx: number = cw / 2;
    let my: number = ch / 2;

    canvas.width = cw;
    canvas.height = ch;

    const random = (min: number, max: number) =>
      min + Math.random() * (max - min);
    const calculateDistance = (
      p1x: number,
      p1y: number,
      p2x: number,
      p2y: number
    ) => Math.sqrt((p1x - p2x) ** 2 + (p2y - p1y) ** 2);

    class Firework {
      x: number;
      y: number;
      sx: number;
      sy: number;
      tx: number;
      ty: number;
      distanceToTarget: number;
      distanceTraveled: number;
      coordinates: [number, number][];
      coordinateCount: number;
      angle: number;
      speed: number;
      acceleration: number;
      brightness: number;
      targetRadius: number;

      constructor(sx: number, sy: number, tx: number, ty: number) {
        this.x = sx;
        this.y = sy;
        this.sx = sx;
        this.sy = sy;
        this.tx = tx;
        this.ty = ty;
        this.distanceToTarget = calculateDistance(sx, sy, tx, ty);
        this.distanceTraveled = 0;
        this.coordinates = [];
        this.coordinateCount = 2;

        while (this.coordinateCount--) {
          this.coordinates.push([this.x, this.y]);
        }
        this.angle = Math.atan2(ty - sy, tx - sx);
        this.speed = 1;
        this.acceleration = 1.2;
        this.brightness = random(10, 50);
        this.targetRadius = 5;
      }

      update() {
        this.coordinates.pop();
        this.coordinates.unshift([this.x, this.y]);
        if (this.targetRadius < 8) {
          this.targetRadius += 0.3;
        } else {
          this.targetRadius = 1;
        }
        this.speed *= this.acceleration;
        const vx = Math.cos(this.angle) * this.speed;
        const vy = Math.sin(this.angle) * this.speed;
        this.distanceTraveled = calculateDistance(
          this.sx,
          this.sy,
          this.x + vx,
          this.y + vy
        );
        if (this.distanceTraveled >= this.distanceToTarget) {
          createParticles(this.tx, this.ty);
          fireworks.splice(fireworks.indexOf(this), 1);
        } else {
          this.x += vx;
          this.y += vy;
        }
      }

      draw(ctx: CanvasRenderingContext2D) {
        if (!ctx) return;

        ctx.beginPath();
        ctx.moveTo(
          this.coordinates[this.coordinates.length - 1][0],
          this.coordinates[this.coordinates.length - 1][1]
        );
        ctx.lineTo(this.x, this.y);
        ctx.strokeStyle = `hsl(${random(0, 360)}, 100%, ${this.brightness}%)`;
        ctx.stroke();
        ctx.beginPath();
        ctx.arc(this.tx, this.ty, this.targetRadius, 0, Math.PI * 2);
        ctx.stroke();
      }
    }

    class Particle {
      x: number;
      y: number;
      coordinates: [number, number][];
      coordinateCount: number;
      angle: number;
      speed: number;
      friction: number;
      gravity: number;
      hue: number;
      brightness: number;
      alpha: number;
      decay: number;

      constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
        this.coordinates = [];
        this.coordinateCount = 5;

        while (this.coordinateCount--) {
          this.coordinates.push([this.x, this.y]);
        }

        this.angle = random(0, Math.PI * 2);
        this.speed = random(3, 15);
        this.friction = 0.95;
        this.gravity = 1;
        this.hue = random(0, 360);
        this.brightness = random(50, 80);
        this.alpha = 1.2;
        this.decay = random(0.015, 0.03);
      }

      update() {
        this.coordinates.pop();
        this.coordinates.unshift([this.x, this.y]);
        this.speed *= this.friction;
        this.x += Math.cos(this.angle) * this.speed;
        this.y += Math.sin(this.angle) * this.speed + this.gravity;
        this.alpha -= this.decay;
        if (this.alpha <= this.decay) {
          particles.splice(particles.indexOf(this), 1);
        }
      }

      draw(ctx: CanvasRenderingContext2D) {
        if (!ctx) return;

        ctx.beginPath();
        ctx.moveTo(
          this.coordinates[this.coordinates.length - 1][0],
          this.coordinates[this.coordinates.length - 1][1]
        );
        ctx.lineTo(this.x, this.y);
        ctx.strokeStyle = `hsla(${this.hue}, 100%, ${this.brightness}%, ${this.alpha})`;
        ctx.stroke();
      }
    }

    const createParticles = (x: number, y: number) => {
      let particleCount = 30;
      while (particleCount--) {
        particles.push(new Particle(x, y));
      }
    };

    const loop = () => {
      requestAnimationFrame(loop);
      ctx.globalCompositeOperation = "destination-out";
      ctx.fillStyle = `rgba(0, 0, 0, 0.5)`;
      ctx.fillRect(0, 0, cw, ch);
      ctx.globalCompositeOperation = "lighter";
      let i = fireworks.length;
      while (i--) {
        fireworks[i].draw(ctx);
        fireworks[i].update();
      }
      let j = particles.length;
      while (j--) {
        particles[j].draw(ctx);
        particles[j].update();
      }
      if (timerTick >= timerTotal) {
        if (!mousedown) {
          mx = Math.random() * cw;
          my = (Math.random() * ch) / 2;
          fireworks.push(new Firework(cw / 2, ch, mx, my));
          timerTotal = Math.random() * 20;
          timerTick = 0;
        }
      } else {
        timerTick++;
      }
      if (limiterTick >= limiterTotal) {
        if (mousedown) {
          fireworks.push(new Firework(cw / 2, ch, mx, my));
          limiterTick = 0;
        }
      } else {
        limiterTick++;
      }
    };

    canvas.addEventListener("mousemove", (e) => {
      mx = e.pageX - canvas.offsetLeft;
      my = e.pageY - canvas.offsetTop;
    });

    canvas.addEventListener("mousedown", (e) => {
      e.preventDefault();
      mousedown = true;
    });

    canvas.addEventListener("mouseup", (e) => {
      e.preventDefault();
      mousedown = false;
    });

    loop();

    window.onresize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
  }, []);

  return <canvas style={{ height: "99%", width: "98vw" }} ref={canvasRef} />;
};

export default Firework;
