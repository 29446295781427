import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WbCloudyRoundedIcon from "@mui/icons-material/WbCloudyRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import viewpaths from "global/constants/ViewPathConstants";
import { useTranslation } from "react-i18next";
import history from "utils/history";
import drawerLayoutStyle from "../Drawer.styles";

const Footer = () => {
  const classes = drawerLayoutStyle;
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  const pageMenu = [
    {
      label: `${t("about")}`,
      url: viewpaths.aboutViewPath,
    },
    {
      label: `${t("calculators")}`,
      url: viewpaths.calculatorsViewPath,
    },
    {
      label: `${t("resources")}`,
      url: viewpaths.resourcesViewPath,
    },
    {
      label: `${t("contact")}`,
      url: viewpaths.contactViewPath,
    },
  ];

  const socialMedia = [
    {
      label: "Facebook",
      icon: FacebookIcon,
      url: "https://www.facebook.com/people/AKARI-Aryaca/100039554235088/",
    },
    {
      label: "Instagram",
      icon: InstagramIcon,
      url: "https://www.instagram.com/akari.aryaca/",
    },
    {
      label: "YouTube",
      icon: YouTubeIcon,
      url: "https://www.youtube.com/@AkariAryaca",
    },
    {
      label: "SoundCloud",
      icon: WbCloudyRoundedIcon,
      url: "https://soundcloud.com/akari_aryaca",
    },
  ];

  const legal = [
    {
      label: `${t("privacyPolicy")}`,
      url: viewpaths.privacyPolicyViewPath,
    },
    // {
    //   label: `${t("termsCondition")}`,
    //   url: viewpaths.landingViewPath,
    // },
  ];

  const getFooter = () => {
    return (
      <Box sx={classes.footerWrapper}>
        <Container maxWidth="lg" sx={{ pb: 5 }}>
          <Grid container spacing={{ md: 2, xs: 4 }}>
            <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
              <Stack direction={"column"} spacing={2}>
                <Typography sx={classes.footerHeader}>
                  {t("footerHeader")}
                </Typography>
                <Typography sx={classes.footerSubHeader}>
                  {t("footerSubHeader")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
              <Grid container spacing={{ lg: 4, md: 2, xs: 4 }}>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                  <Stack direction={"column"} spacing={2}>
                    <Typography sx={classes.pageHeader}>
                      {t("company")}
                    </Typography>
                    {pageMenu.map((page: any) => {
                      return (
                        <Typography
                          sx={{
                            ...classes.footerSubHeader,
                            cursor: "pointer",
                            ...classes.footerSubHeaderHover,
                          }}
                          onClick={() => history.push(page.url)}
                        >
                          {page.label}
                        </Typography>
                      );
                    })}
                  </Stack>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                  <Stack direction={"column"} spacing={2}>
                    <Typography sx={classes.pageHeader}>
                      {t("socialMedia")}
                    </Typography>
                    {socialMedia.map((page: any) => {
                      return (
                        <Stack
                          direction={"row"}
                          spacing={1}
                          sx={{ cursor: "pointer" }}
                          onClick={() => window.open(page.url, "_blank")}
                        >
                          <page.icon htmlColor="#ffffff" />
                          <Typography
                            sx={{
                              ...classes.footerSubHeader,
                              ...classes.footerSubHeaderHover,
                            }}
                          >
                            {page.label}
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Stack>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                  <Stack direction={"column"} spacing={2}>
                    <Typography sx={classes.pageHeader}>
                      {t("legal")}
                    </Typography>
                    {legal.map((page: any) => {
                      return (
                        <Typography
                          sx={{
                            ...classes.footerSubHeader,
                            cursor: "pointer",
                            ...classes.footerSubHeaderHover,
                          }}
                          onClick={() => history.push(page.url)}
                        >
                          {page.label}
                        </Typography>
                      );
                    })}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Typography
          pb={5}
          textAlign={"center"}
          sx={{ ...classes.footerSubHeader, fontSize: "14px", width: "100%" }}
        >
          &copy; {currentYear} {t("footer")}
        </Typography>
      </Box>
    );
  };

  return getFooter();
};

export default Footer;
