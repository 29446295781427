import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import footballStyles from "./Football.styles";

interface Category {
  firstChoiceName: string;
  secondChoiceName: string;
}

interface PlayerProps {
  positionName: string;
  position: string;
  category: Category;
  percentage: number;
  goldenFoot: boolean;
}

const Player: React.FC<PlayerProps> = ({
  positionName,
  position,
  category,
  percentage,
  goldenFoot,
}) => {
  const classes = footballStyles;

  const getStyles = (pos: string, percentage: number) => {
    if (percentage === 0) {
      return {};
    }
    if (pos === category.firstChoiceName)
      return { background: goldenFoot ? "#E5B80B" : "#222222" };
    if (pos === category.secondChoiceName) return { background: "grey" };
    return {};
  };
  const getNumberStyles = (pos: string, percentage: number) => {
    if (percentage === 0) {
      return {};
    }
    if (pos === category.firstChoiceName)
      return { color: goldenFoot ? "#000000" : "#ffffff" };
    if (pos === category.secondChoiceName) return { color: "#ffffff" };
    return {};
  };

  return (
    <Grid
      item
      xl={3}
      lg={3}
      md={3}
      sm={3}
      xs={3}
      display="flex"
      justifyContent="center"
      alignSelf={positionName === "CM" ? "end" : "start"}
    >
      <Box
        sx={{
          ...classes.tshirt,
          ...getStyles(position, percentage),
        }}
      >
        <Box
          sx={[
            { ...classes.arm, ...getStyles(position, percentage) },
            classes.armLeft,
          ]}
        ></Box>
        <Box
          sx={[
            { ...classes.arm, ...getStyles(position, percentage) },
            classes.armRight,
          ]}
        ></Box>
        <Box sx={{ ...classes.body, ...getNumberStyles(position, percentage) }}>
          <Box sx={classes.number}>{percentage ? `${percentage}%` : ""}</Box>
        </Box>
        <Typography sx={classes.positionName}>{positionName}</Typography>
      </Box>
    </Grid>
  );
};

interface FootballGroundProps {
  category: Category;
  goldenFoot: boolean;
  percentages: any;
}

const FootballGround: React.FC<FootballGroundProps> = ({
  category,
  goldenFoot,
  percentages,
}) => {
  const classes = footballStyles;
  const { t } = useTranslation();
  const positions: Record<string, string[]> = useMemo(
    () => ({
      Striker: ["ST"],
      Forward: ["LW", "RW"],
      Midfielder: ["LM", "CM", "RM"],
      Defender: ["LB", "LCB", "RCB", "RB"],
      Goalkeeper: ["GK"],
    }),
    []
  );

  const dotStyles = (color: string) => ({
    width: "13px",
    height: "13px",
    borderRadius: "50%",
    backgroundColor: color,
    marginRight: "5px",
  });

  return (
    <Stack
      spacing={1.5}
      height="100%"
      sx={classes.groundBackground}
      justifyContent="space-evenly"
    >
      <Box sx={classes.whiteBoxStyles}>
        {goldenFoot ? (
          <Box sx={classes.labelStyles}>
            <Box sx={dotStyles("#E5B80B")} />
            <Typography>{t("goldenPlayer")}</Typography>
          </Box>
        ) : (
          <Box sx={classes.labelStyles}>
            <Box sx={dotStyles("#222222")} />
            <Typography>{t("firstChoice")}</Typography>
          </Box>
        )}

        <Box sx={classes.labelStyles}>
          <Box sx={dotStyles("grey")} />
          <Typography>{t("secondChoice")}</Typography>
        </Box>
        <Box sx={classes.labelStyles}>
          <Box sx={dotStyles("white")} />
          <Typography>{t("normalPlayer")}</Typography>
        </Box>
      </Box>
      {Object.keys(positions).map((position) => (
        <Grid
          container
          justifyContent={position === "Forward" ? "space-between" : "center"}
          height={position === "Midfielder" ? "140px" : "fit-content"}
          key={position}
        >
          {positions[position].map((value, index) => (
            <Player
              key={`${position}-${index}`}
              positionName={value}
              position={position}
              category={category}
              percentage={percentages[position][index]}
              goldenFoot={goldenFoot}
            />
          ))}
        </Grid>
      ))}
    </Stack>
  );
};

export default FootballGround;
