import {
  getRelativeFontSize,
  mediumFont,
  primaryColorWhite,
  regularFont,
  theme,
} from "utils/styles";
import contactUsBackImage from "../../assets/images/contactUsBackImage.svg";

const contactStyle = {
  backImg: {
    minHeight: "62vh",
    height: "100%",
    background: `url(${contactUsBackImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
  innerBackground: {
    backgroundColor: "rgba(51,59,79,0.98)",
    color: "inherit",
    position: "absolute",
    width: "100%",
    height: "100% !important",
    left: 0,
    top: 0,
  },
  headerText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(50),
    color: primaryColorWhite,
    lineHeight: "5.5rem",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(40),
      lineHeight: "5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(30),
      lineHeight: "4.5rem",
    },
  },
  subHeaderText: {
    ...regularFont,
    fontSize: getRelativeFontSize(4),
    color: "#c7c8c9",
    linHeight: "19px",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(3),
    },
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  calculatorHeader: {
    ...mediumFont,
    fontSize: getRelativeFontSize(5),
    color: primaryColorWhite,
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(3),
    },
  },
  contactFormHeader: {
    ...regularFont,
    fontSize: getRelativeFontSize(9),
    color: primaryColorWhite,
    textAlign: "center",
    width: "80%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(5),
      width: "100%",
    },
  },
  contactButtonText: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#697582",
    width: "fit-content",
  },
  contactButtonTextHover: {
    display: "inline-block",
    verticalAlign: "middle",
    transform: "perspective(1px) translateZ(0)",
    boxShadow: "0 0 1px rgba(0, 0, 0, 0)",
    position: "relative",
    overflow: "hidden",
    "&:before": {
      content: '""',
      position: "absolute",
      zIndex: -1,
      left: "51%",
      right: "51%",
      bottom: 0,
      backgroundColor: "#c7c8c9",
      color: "#ffffff",
      height: "1px",
      transitionProperty: "left, right",
      transitionDuration: "0.3s",
      transitionTimingFunction: "ease-out",
    },
    "&:hover:before, &:focus:before, &:active:before": {
      left: 0,
      right: 0,
    },
    "&:hover, &:focus, &:active": {
      color: "#ffffff",
    },
  },
  contactBtn: {
    border: "none",
    minWidth: "-webkit-fill-available",
    height: "50px !important",
  },
  contactNoteText: {
    color: "rgba(255,255,255,.5)!important",
    ...regularFont,
    fontSize: getRelativeFontSize(2),
  },
} as const;

export default contactStyle;
