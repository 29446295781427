import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import CustomButton from "global/components/CustomButton/CustomButton";
import viewpaths from "global/constants/ViewPathConstants";
import { useTranslation } from "react-i18next";
import AnimatedSection from "screens/Shared/AnimationSection";
import history from "utils/history";
import homeStyles from "../Home.styles";

const ContactUsSection = () => {
  const classes = homeStyles;
  const { t } = useTranslation();

  return (
    <Box sx={classes.contactBackground}>
      <Grid
        container
        spacing={2}
        justifyContent={"end"}
        sx={{ zIndex: 1, height: "100%", alignContent: "center", my: 10 }}
      >
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mr={10}>
          <Container maxWidth="sm">
            <Stack direction={"column"} spacing={4}>
              <AnimatedSection animationType="fadeInUp">
                <Typography
                  sx={{ ...classes.contentHeaderText, textAlign: "start" }}
                >
                  {t("contactUsHeader")}
                </Typography>
              </AnimatedSection>
              <AnimatedSection animationType="fadeInUp">
                <Typography sx={classes.calculatorHeader}>
                  {t("contactUsDesc")}
                </Typography>
              </AnimatedSection>
              <AnimatedSection animationType="fadeInUp">
                <CustomButton
                  label={t("contactUs")}
                  buttonType="gradientBtn"
                  onClick={() => history.push(viewpaths.contactViewPath)}
                  customClasses={classes.ourServiceBtn}
                  endIcon={<EastOutlinedIcon />}
                />
              </AnimatedSection>
              <Grid container gap={"20px"}>
                {[1, 2, 3, 4].map((index: number) => {
                  return (
                    <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
                      <AnimatedSection animationType="fadeInUp">
                        <Stack
                          direction={"row"}
                          spacing={2}
                          alignItems={"center"}
                        >
                          <Box
                            sx={{
                              background:
                                "linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%)",
                              padding: "5px",
                              borderRadius: "50%",
                              display: "flex",
                            }}
                          >
                            <DoneOutlinedIcon
                              htmlColor="#ffffff"
                              sx={{ fontSize: "18px" }}
                            />
                          </Box>
                          <Typography
                            sx={{
                              ...classes.calculatorHeader,
                              fontSize: "15px",
                            }}
                          >
                            {t(`contactPoint${index}`)}
                          </Typography>
                        </Stack>
                      </AnimatedSection>
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUsSection;
