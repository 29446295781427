import { Box, Typography, useMediaQuery } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { useTranslation } from "react-i18next";
import DesktopLoader from "../../../assets/images/loader1.mp4";
import MobileLoader from "../../../assets/images/loader2.mp4";
import {
  getRelativeFontSize,
  mediumFont,
  primaryColorWhite,
  theme,
} from "../../../utils/styles";

interface CustomLoaderProps {
  isLoading?: boolean;
}

const CustomLoader = (props: CustomLoaderProps) => {
  const { t } = useTranslation();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const imgStyle = {
    width: "100vw",
    height: "100vh",
  };

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme: any) => theme.zIndex.drawer + 101,
        backgroundColor: "#000000 !important",
      }}
      open={props.isLoading!}
    >
      <Box height={"100vh"} width={"100vw"} alignContent={"center"}>
        <Typography
          sx={{
            position: "absolute",
            top: 50,
            left: 10,
            color: primaryColorWhite,
            fontSize: getRelativeFontSize(16),
            ...mediumFont,
            zIndex: 2,
            [theme.breakpoints.down("sm")]: {
              fontSize: getRelativeFontSize(3),
            },
          }}
        >
          {t("goldenFootLabel")}
        </Typography>
        {props.isLoading ? (
          isTablet ? (
            <video style={imgStyle} autoPlay>
              <source src={MobileLoader} type="video/mp4" />
            </video>
          ) : (
            <video style={imgStyle} autoPlay>
              <source src={DesktopLoader} type="video/mp4" />
            </video>
          )
        ) : null}
      </Box>
    </Backdrop>
  );
};

export default CustomLoader;
