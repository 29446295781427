import type { PaletteColor } from "@mui/material/styles/createPalette";
import type { ColorPreset } from ".";
import {  schoolapp } from "./colors";

export const getPrimary = (preset?: ColorPreset): PaletteColor => {
  switch (preset) {
    case "schoolapp":
      return schoolapp;
    default:
      return schoolapp;
  }
};
