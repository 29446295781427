import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  keyframes,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "global/components/CustomButton/CustomButton";
import viewpaths from "global/constants/ViewPathConstants";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AnimatedSection from "screens/Shared/AnimationSection";
import history from "utils/history";
import { animationStyle, theme } from "utils/styles";
import servicesImage from "../../assets/images/servicesImage.webp";
import resourcesStyle from "./Resources.styles";
import { articles, color, publications } from "./ResourcesData";

const animation = makeStyles(animationStyle);

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: "#242a3a",
  borderRadius: "0px",
  borderTop: "2px solid transparent",
  borderBottom: "2px solid transparent",
  borderImage: `linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%) 1`,
  margin: 0,
  "&:first-of-type": {
    borderTop: "2px solid transparent",
    borderImage: `linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%) 1`,
  },
  "&:last-of-type": {
    borderBottom: "2px solid transparent",
    borderImage: `linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%) 1`,
  },
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    margin: 0,
  },
}));

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Resources = () => {
  const classes = resourcesStyle;
  const animationClasses = animation();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const getHeader = () => {
    return (
      <Box
        sx={{
          background: "#2f3749",
        }}
      >
        <Box sx={classes.backImg}>
          <Box sx={classes.innerBackground}>
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
              maxHeight={"62vh"}
              spacing={2}
              className={animationClasses.fadeInDown}
            >
              <Typography sx={classes.headerText}>{t("resources")}</Typography>
              <Typography sx={classes.subHeaderText}>
                {t("resourcesHeader")}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
    );
  };

  const getArticles = () => {
    return (
      <Box sx={classes.darkBackground}>
        <Container maxWidth="xl">
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={5}
          >
            <Typography
              sx={classes.gradientText}
              className={animationClasses.fadeInDown}
            >
              {t("articles")}
            </Typography>
            <Typography
              sx={classes.contentHeaderText}
              className={animationClasses.fadeInDown}
            >
              {t("articlesDesc")}
            </Typography>
            <Grid container spacing={2} justifyContent={"center"}>
              {articles.map((article: any, index: number) => {
                return (
                  <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                    <AnimatedSection animationType="textWave">
                      <Box sx={classes.reasonBox}>
                        <Box sx={classes.greyBox}>
                          <Box
                            component="img"
                            src={article.image}
                            alt={article.image}
                            sx={classes.resourcesImage}
                          />
                          <Stack
                            direction={"column"}
                            justifyContent={"space-between"}
                            spacing={2}
                            p={3}
                          >
                            <Typography sx={classes.titleText}>
                              {article.title}
                            </Typography>
                            <Typography sx={classes.descriptionText}>
                              {article.description}
                            </Typography>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                gap={"8px"}
                              >
                                <Box
                                  component="img"
                                  src={article.authorImage}
                                  alt={article.authorImage}
                                  sx={{
                                    height: "30px",
                                  }}
                                />
                                <Typography sx={classes.authorText}>
                                  {article.author}
                                </Typography>
                              </Box>
                              <Typography sx={classes.authorText}>
                                {article.date}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Box>
                      </Box>
                    </AnimatedSection>
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </Container>
      </Box>
    );
  };

  const getPublications = () => {
    return (
      <Box sx={classes.darkBackground}>
        <Container maxWidth="xl">
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={5}
          >
            <AnimatedSection animationType="textWave">
              <Typography sx={classes.gradientText}>
                {t("publications")}
              </Typography>
            </AnimatedSection>
            <AnimatedSection animationType="textWave">
              <Typography sx={classes.contentHeaderText}>
                {t("publicationsDesc")}
              </Typography>
            </AnimatedSection>
            <Grid container spacing={2} justifyContent={"center"}>
              {publications.map((publication: any, index: number) => {
                return (
                  <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                    <AnimatedSection animationType="textWave">
                      <Box sx={classes.reasonBox}>
                        <Box sx={classes.greyBox}>
                          <Box
                            component="img"
                            src={publication.image}
                            alt={publication.image}
                            sx={classes.resourcesImage}
                          />
                          <Stack
                            direction={"column"}
                            justifyContent={"space-between"}
                            spacing={2}
                            p={3}
                          >
                            <Typography sx={classes.titleText}>
                              {publication.title}
                            </Typography>
                            <Typography sx={classes.descriptionText}>
                              {publication.description}
                            </Typography>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                gap={"8px"}
                              >
                                <Box
                                  component="img"
                                  src={publication.authorImage}
                                  alt={publication.authorImage}
                                  sx={{
                                    height: "30px",
                                  }}
                                />
                                <Typography sx={classes.authorText}>
                                  {publication.author}
                                </Typography>
                              </Box>
                              <Typography sx={classes.authorText}>
                                {publication.date}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Box>
                      </Box>
                    </AnimatedSection>
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </Container>
      </Box>
    );
  };

  const getFAQs = () => {
    return (
      <Box sx={classes.darkBackground}>
        <Container maxWidth="xl">
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={5}
          >
            <AnimatedSection animationType="textWave">
              <Typography sx={classes.gradientText}>{t("faqs")}</Typography>
            </AnimatedSection>
            <AnimatedSection animationType="textWave">
              <Typography sx={classes.contentHeaderText}>
                {t("faqsDesc")}
              </Typography>
            </AnimatedSection>
            <Stack direction={"column"} width={"100%"}>
              <AnimatedSection animationType="textWave">
                {Array.from({ length: 6 }).map((v: any, index: number) => {
                  return (
                    <CustomAccordion
                      expanded={expanded === `panel${index + 1}`}
                      onChange={handleChange(`panel${index + 1}`)}
                    >
                      <AccordionSummary>
                        <Typography
                          sx={
                            expanded === `panel${index + 1}`
                              ? {
                                  ...classes.questionText,
                                  color: color[index],
                                }
                              : classes.questionText
                          }
                        >
                          {index + 1 + ". "} {t(`faq${index + 1}`)}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {Array.from({ length: 2 }).map(
                          (v: any, ansIndex: number) => {
                            return (
                              <Typography sx={classes.answerText} pb={2}>
                                {t(`faq${index + 1}Ans${ansIndex + 1}`)}
                              </Typography>
                            );
                          }
                        )}
                      </AccordionDetails>
                    </CustomAccordion>
                  );
                })}
              </AnimatedSection>
            </Stack>
          </Stack>
        </Container>
      </Box>
    );
  };

  const getContactUs = () => {
    return (
      <Box sx={classes.darkBackground}>
        <Container maxWidth="md">
          <Stack
            direction={{ md: "row", xs: "column" }}
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box
              component="img"
              src={servicesImage}
              alt={"icon"}
              sx={{
                animation: `${rotate} 10s linear infinite`,
              }}
            />
            <Stack direction={"column"} spacing={3}>
              <Typography sx={classes.contentHeaderText}>
                {t("needInfo")}
              </Typography>
              <Typography
                sx={{
                  ...classes.contentHeaderText,
                  fontSize: "22px",
                  lineHeight: 1.8,
                  [theme.breakpoints.down("md")]: {
                    fontSize: "18px",
                    lineHeight: 1.5,
                  },
                }}
              >
                {t("needInfoDesc")}
              </Typography>
            </Stack>

            <CustomButton
              label={t("contactUs")}
              buttonType="gradientBtn"
              onClick={() => history.push(viewpaths.contactViewPath)}
              customClasses={classes.contactBtn}
              endIcon={<EastOutlinedIcon />}
            />
          </Stack>
        </Container>
      </Box>
    );
  };

  const getResources = () => {
    return (
      <Box>
        {getHeader()}
        {getArticles()}
        {getPublications()}
        {getFAQs()}
        {getContactUs()}
      </Box>
    );
  };

  return getResources();
};

export default Resources;
