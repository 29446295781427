import SouthIcon from "@mui/icons-material/South";
import {
  Box,
  Container,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "global/components/CustomButton/CustomButton";
import viewpaths from "global/constants/ViewPathConstants";
import { useTranslation } from "react-i18next";
import history from "utils/history";
import { animationStyle } from "utils/styles";
import decoration1 from "../../../assets/images/decoration-1.svg";
import heroImage from "../../../assets/images/heroImage.jpg";
import homeStyles from "../Home.styles";

const smoothScroll = (target: any, duration: number) => {
  const start = window.pageYOffset;
  const targetPosition = target.getBoundingClientRect().top;
  const end = targetPosition + start;
  const distance = end - start - 50;
  let startTime: number | null = null;

  const animation = (currentTime: number) => {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const progress = Math.min(timeElapsed / duration, 1);
    window.scrollTo(0, start + distance * easeInOutQuad(progress));
    if (timeElapsed < duration) {
      window.requestAnimationFrame(animation);
    }
  };

  // Easing function
  const easeInOutQuad = (t: number) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };

  window.requestAnimationFrame(animation);
};

const handleScrollToSection = (sectionRef: React.RefObject<HTMLElement>) => {
  if (sectionRef.current) {
    smoothScroll(sectionRef.current, 2000); // 1 second duration
  }
};

const animation: any = makeStyles(animationStyle);

const HeroSection = ({
  section1Ref,
  section2Ref,
  section3Ref,
}: {
  section1Ref: React.RefObject<HTMLElement>;
  section2Ref: React.RefObject<HTMLElement>;
  section3Ref: React.RefObject<HTMLElement>;
}) => {
  const classes = homeStyles;
  const animationClasses = animation();
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const { t } = useTranslation();

  const getHeroImage = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 630 626"
        style={{ width: "100%", height: "100%" }}
      >
        <defs>
          <pattern
            id="img-pattern"
            patternUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <image
              href={heroImage}
              x="0"
              y="0"
              width="630"
              height="626"
              preserveAspectRatio="xMidYMid slice"
              className={animationClasses.zoomOut}
            />
          </pattern>
        </defs>
        <path
          fill="url(#img-pattern)"
          fillRule="evenodd"
          d="M514.525,490.107 C415.246,531.896 348.747,414.950 289.043,497.760 C244.761,559.177 167.936,659.495 96.067,614.227 C-3.228,551.684 -88.205,114.891 183.088,14.496 C286.891,-23.917 491.321,15.231 581.881,167.001 C681.714,334.310 606.813,451.260 514.525,490.107 Z"
        />
      </svg>
    );
  };

  return (
    <Box sx={classes.backImg} ref={section1Ref}>
      <Container maxWidth="xl">
        <Grid container height={"100%"} px={{ md: "50px", xs: "10px" }}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Stack
              pt={"100px"}
              direction={"column"}
              spacing={{ md: 5, xs: 2 }}
              className={animationClasses.fadeInDown}
            >
              <Typography sx={classes.headerText} pt={2}>
                {t("heroHeader")}
              </Typography>
              <Typography sx={{ ...classes.subHeaderText, maxWidth: "400px" }}>
                {t("heroSubHeader")}
              </Typography>
              <Stack
                direction={{ sm: "row", xs: "column" }}
                spacing={1}
                alignItems={"center"}
              >
                <CustomButton
                  label={t("ourServices")}
                  buttonType="gradientBtn"
                  onClick={() => history.push(viewpaths.calculatorsViewPath)}
                  customClasses={classes.ourServiceBtn}
                />
                <CustomButton
                  label={t("learnMore")}
                  buttonType="transparentGradientBtn"
                  onClick={() => handleScrollToSection(section3Ref)}
                  customClasses={classes.ourServiceBtn}
                />
              </Stack>
              <Stack
                direction={"row"}
                spacing={1}
                alignItems={"center"}
                onClick={() => handleScrollToSection(section2Ref)}
                sx={{
                  cursor: "pointer",
                  width: "fit-content",
                  display: "flex !important",
                }}
                className="hvr-bounce-in"
              >
                <Box sx={classes.downArrowWrapper}>
                  <SouthIcon htmlColor="#c7c8c9" sx={{ fontSize: "20px" }} />
                </Box>
                <Typography sx={classes.scrollDownText}>
                  {t("scrollDown")}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box pt={{ md: "100px", xs: "20px" }} pb={{ sm: 0, xs: 2 }}>
              <Box sx={classes.heroDecorationWrapper}>
                {smUp && (
                  <Box sx={classes.heroDecorationImage1}>
                    <img src={decoration1} alt="decoration" />
                  </Box>
                )}
                <Box sx={classes.heroImage}>{getHeroImage()}</Box>
                {smUp && (
                  <Box sx={classes.heroDecorationImage2}>
                    <img src={decoration1} alt="decoration" />
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HeroSection;
