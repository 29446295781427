import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { RootState } from "utils/store";
import { Resources } from "models/interfaces";

export interface Primary {
  id: string;
  email: string;
  name: string;
  officePhone: string;
  lastLogin: any;
}

export interface AuthState {
  authenticated: boolean;
  accessToken: string;
  refreshToken: string;
  loading: boolean;
  userName: string;
  userId: string;
  email: string;
  clientId: any;
  userRole: string;
  companyId: string;
  resources: Resources[];
}

const initialState: AuthState = {
  authenticated: false,
  accessToken: "",
  refreshToken: "",
  loading: false,
  userName: "",
  email: "",
  userId: "",
  userRole: "",
  clientId: "",
  companyId: "",
  resources: [],
};

export const authSlice: Slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginAction: (
      state,
      action: PayloadAction<{
        authenticated: boolean;
        accessToken: string;
        refreshToken: string;
        userName: string;
        email: string;
        userRole: string;
        companyId: string;
        resources: Resources[];
      }>
    ) => {
      state.authenticated = action.payload.authenticated;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.userName = action.payload.userName;
      state.email = action.payload.email;
      state.userRole = action.payload.userRole;
      state.companyId = action.payload.companyId;
      state.resources = action.payload.resources;
    },
    addLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    logOutAction: (state) => {
      state.authenticated = false;
      state.loading = false;
      state.accessToken = "";
      state.userId = "";
      state.userName = "";
      state.clientId = "";
      state.resources = [];
    },
    updateAccessToken: (
        state,
        action: PayloadAction<{ accessToken: string; refreshToken: string }>
    ) => {
      state.accessToken = action.payload.accessToken;
    },
    // primaryUserAction: (
    //   state,
    //   action: PayloadAction<{
    //     id: string;
    //     email: string;
    //     name: string;
    //     officePhone: string;
    //     lastLogin: any;
    //   }>
    // ) => {
    //   state.primary.id = action.payload.id;
    //   state.primary.email = action.payload.email;
    //   state.primary.name = action.payload.name;
    //   state.primary.officePhone = action.payload.officePhone;
    //   state.primary.lastLogin = action.payload.lastLogin;
    // },
    makeAppDrawerHide: (state, action: PayloadAction<boolean>) => {
      state.hideAppDrawer = action.payload;
    },
    updateUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
    updateEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
  },
});

export const {
  loginAction,
  logOutAction,
  addLoading,
  makeAppDrawerHide,
  updateUserName,
  updateEmail,
  updateAccessToken,
} = authSlice.actions;

export const selectAuthenticated = (state: RootState) =>
  state.auth.authenticated;
export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectUserRole = (state: RootState) => state.auth.userRole;
export const selectClientId = (state: RootState) => state.auth.clientId;
export const selectId = (state: RootState) => state.auth.userId;
export const selectResources = (state: RootState) => state.auth.resources;
export const selectLoading = (state: RootState) => state.auth.loading;
export const companyId = (state: RootState) => state.auth.companyId;
export const selectUserName = (state: RootState) => state.auth.userName;
export const selectEmail = (state: RootState) => state.auth.email;
// export const isUserRole = (state: RootState) =>
//   state.auth.userRole === strings.CONSULTANT ||
//   state.auth.userRole === strings.EMPLOYEE ||
//   state.auth.userRole === strings.OPERATIONS;

export default authSlice.reducer;
