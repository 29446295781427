import {
  getRelativeFontSize,
  mediumFont,
  primaryColorWhite,
  regularFont,
  theme,
} from "utils/styles";
import contactUsBackImage from "../../assets/images/contactUsBackImage.svg";
import aboutImage4 from "../../assets/images/content1.jpg";

const aboutStyle = {
  backImg: {
    minHeight: "62vh",
    height: "100%",
    background: `url(${contactUsBackImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
  },
  darkBackground: {
    background: "#2f3749",
    // background:
    //   "-webkit-linear-gradient(top, rgb(56, 64, 83) 0%, rgb(47, 55, 73) 100%)",
    position: "relative",
    boxShadow: "inset 0px 12px 4px -11px #474a51",
  },
  innerBackground: {
    backgroundColor: "rgba(51,59,79,0.98)",
    color: "inherit",
    position: "absolute",
    width: "100%",
    height: "100% !important",
    left: 0,
    top: 0,
  },
  headerText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(50),
    color: primaryColorWhite,
    lineHeight: "5.5rem",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(40),
      lineHeight: "5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(30),
      lineHeight: "4.5rem",
    },
  },
  subHeaderText: {
    ...regularFont,
    fontSize: getRelativeFontSize(4),
    color: "#c7c8c9",
    linHeight: "19px",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(3),
    },
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  gradientText: {
    background:
      "linear-gradient(90deg, #ff68cc 0%, #dc5fff 33%, #a080ff 67%, #7f99ff 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    ...mediumFont,
    fontSize: getRelativeFontSize(12),
    width: "fit-content",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(10),
    },
  },
  contentHeaderText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(36),
    color: primaryColorWhite,
    textAlign: "center",
    lineHeight: 1.2,
    maxWidth: "1000px",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(20),
    },
  },
  titleText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(8),
    color: primaryColorWhite,
    pb: 1,
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(4),
    },
  },
  descriptionText: {
    ...regularFont,
    fontSize: getRelativeFontSize(4),
    color: "#c7c8c9",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  pointsWrapper: {
    display: "flex",
    alignItems: "stretch",
    position: "relative",
    right: "100px",
    pt: 12,
    maxWidth: "1000px",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "fit-content",
    },
    [theme.breakpoints.down("md")]: {
      pt: 2,
    },
  },
  smallDecoration: {
    height: "auto",
    width: "10px",
    margin: "50px 0",
  },
  largeDecoration: {
    // height: "auto",
    width: "10px",
    // margin: "auto 0",
  },
  leftRadiusBox: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  rightRadiusBox: {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  noLeftRadiusBox: {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
  noRightRadiusBox: {
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  pointsImage: {
    height: "180px",
    width: "100%",
    objectFit: "fill",
    borderRadius: "10px",
    transform: "scaleY(1.7)",
    [theme.breakpoints.down("md")]: {
      transform: "scaleY(1)",
      height: "300px",
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "200px",
    },
  },
  missionWrapper: {
    p: 3,
    background: "#1b202a",
    borderRadius: "10px",
    flex: 1,
  },
  image1Box: {
    position: "relative",
    width: "0px",
    // bottom: "50px",
    right: "40px",
  },
  image1: {
    borderRadius: "6px",
    height: "550px",
    width: "350px",
  },
  image2Box: {
    position: "relative",
  },
  image2: {
    height: "350px",
    borderRadius: "6px",
  },
  image3: {
    borderRadius: "6px",
    height: "550px",
    width: "125px",
    position: "relative",
    bottom: "25px",
  },
  authorBackground: {
    position: "relative",
    backgroundImage: `url(${aboutImage4})`,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    backgroundSize: "cover",
    backgroundPosition: "center",
    bottom: 0,

    "&:before": {
      zIndex: 0,
      position: "absolute",
      background:
        "linear-gradient(90deg, rgb(19 0 17 / 70%) 0%, rgb(41 40 81) 75%, rgb(68 42 94 / 75%) 100%)",
      content: `""`,
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  },
  authorImage: {
    height: "auto",
    maxHeight: "600px",
    [theme.breakpoints.down("lg")]: {
      width: "450px",
    },
    [theme.breakpoints.down("md")]: {
      width: "400px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  aboutAuthorText: {
    ...regularFont,
    fontSize: getRelativeFontSize(6),
    color: primaryColorWhite,
    maxWidth: "500px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      fontSize: getRelativeFontSize(3),
    },
  },
  bookNameText: {
    ...mediumFont,
    fontSize: getRelativeFontSize(20),
    color: primaryColorWhite,
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(13),
    },
  },
  aboutBookText: {
    ...regularFont,
    fontSize: getRelativeFontSize(4),
    color: "#c7c8c9",
    display: "list-item",
    listStyleType: "square",
    marginLeft: "30px !important",
    [theme.breakpoints.down("md")]: {
      fontSize: getRelativeFontSize(2),
    },
  },
  readMoreText: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    color: "#00b2ff",
    cursor: "pointer",
    marginLeft: "30px !important",
  },
} as const;

export default aboutStyle;
