import urls from "global/constants/UrlConstants";
import { getNoAuthCallParams, makeCall } from "utils/service";

export const submitContactForm = async (contactForm: any) => {
  try {
    const callParams = await getNoAuthCallParams("POST", contactForm);
    const response: any = await makeCall(urls.CONTACT_US, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
