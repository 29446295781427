import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { borderRadius, theme } from "../../../utils/styles";
import customDialogStyles from "./CustomDialog.styles";

interface CustomProps {
  handleDialogClose?: any;
  closable?: boolean;
  width?: any;
  isDialogOpen: boolean;
  dialogTitleContent?: JSX.Element;
  dialogBodyContent: JSX.Element;
  dialogFooterContent?: JSX.Element;
  dialogFooterClass?: any;
  closeButtonVisibility?: boolean;
  fullScreen?: boolean;
  borderRadius?: string;
  dialogHeaderContent?: JSX.Element;
}

const CustomDialog = (props: CustomProps) => {
  const classes = customDialogStyles;
  const width = props.width ? props.width : "60%";
  const radius = props.borderRadius ? props.borderRadius : borderRadius;

  const customStyles = {
    dialogWidth: {
      ".MuiDialog-paper": {
        width: width,
        maxWidth: "none",
        borderRadius: radius,
        border: "1px solid #ffffff78",
        boxShadow: "0px 5px 22px 0px #242A3A0D",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
    },
  };
  const customClasses = customStyles;

  const getDialogHeader = () => {
    return (
      props.dialogHeaderContent !== undefined && (
        <Box sx={classes.headerStyle}>
          {props?.dialogHeaderContent !== undefined && (
            <Box sx={{ width: "100%" }}>{props?.dialogHeaderContent}</Box>
          )}
          {props.closable && props.handleDialogClose !== undefined && (
            <Box
              sx={classes.closeButtonContainer}
              onClick={props.handleDialogClose}
            >
              {props.closeButtonVisibility ? (
                <IconButton aria-label="close" sx={classes.closeButton}>
                  <CloseIcon />
                </IconButton>
              ) : null}
            </Box>
          )}
        </Box>
      )
    );
  };

  const getDialogTitle = () => {
    return (
      props.dialogTitleContent !== undefined && (
        <DialogTitle id="customized-dialog-title" sx={classes.dialogTitle}>
          <Box sx={classes.displayFlex}>
            {props.dialogTitleContent !== undefined && (
              <Box style={{ width: "100%" }}>{props.dialogTitleContent}</Box>
            )}
          </Box>
        </DialogTitle>
      )
    );
  };

  const getDialogBody = () => {
    return (
      <DialogContent sx={classes.dialogContent}>
        {props.dialogBodyContent}
      </DialogContent>
    );
  };

  const getDialogFooter = () => {
    return (
      props.dialogFooterContent !== undefined && (
        <DialogActions
          sx={
            props.dialogFooterClass !== undefined
              ? (classes.dialogActions, props.dialogFooterClass)
              : classes.dialogActions
          }
        >
          {props.dialogFooterContent}
        </DialogActions>
      )
    );
  };

  return (
    <Dialog
      fullScreen={props.fullScreen}
      onClose={props.handleDialogClose}
      aria-labelledby="customized-dialog-title"
      open={props.isDialogOpen}
      sx={customClasses.dialogWidth}
    >
      {getDialogHeader()}
      {getDialogTitle()}
      {getDialogBody()}
      {getDialogFooter()}
    </Dialog>
  );
};

export default CustomDialog;
