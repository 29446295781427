import { Stack, styled, Switch, SwitchProps, Typography } from "@mui/material";
import CustomDialog from "global/components/CustomDialog/CustomDialog";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import footballStyles from "./Football.styles";

interface CustomProps {
  isOpen: boolean;
  setIsOpen: Function;
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    color: "#fff",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#65C466",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const FootballDialog = (props: CustomProps) => {
  const classes = footballStyles;
  const { t } = useTranslation();
  const [isEnglish, setIsEnglish] = useState<boolean>(true);

  const handleClose = () => {
    props.setIsOpen(false);
    setIsEnglish(true);
  };

  const dialogHeaderContent = () => {
    return (
      <Stack
        direction={{ sm: "row", xs: "column" }}
        justifyContent={"center"}
        alignItems={"center"}
        pb={2}
        px={2}
      >
        <Typography sx={classes.dialogHeaderText}>{t("howItWorks")}</Typography>
      </Stack>
    );
  };

  const dialogBodyContent = () => {
    return (
      <Stack direction={"column"} spacing={2}>
        <Typography sx={classes.dialogBoldFont}>{t("hitHeader1")}</Typography>
        <Typography sx={classes.dialogRegularFont}>{t("hitDesc1")}</Typography>
        <Typography sx={classes.dialogBoldFont}>{t("hitHeader2")}</Typography>
        <Typography sx={classes.dialogRegularFont}>{t("hitDesc2")}</Typography>
        <Typography sx={classes.dialogRegularFont}>{t("hitDesc3")}</Typography>
        <Typography sx={classes.dialogRegularFont}>{t("hitDesc4")}</Typography>
        <Typography sx={classes.dialogBoldFont}>{t("hitHeader3")}</Typography>
        <Typography sx={classes.dialogRegularFont}>{t("hitDesc5")}</Typography>
        <Typography sx={classes.dialogBoldFont}>{t("hitHeader4")}</Typography>
        <Typography sx={classes.dialogRegularFont}>{t("hitDesc6")}</Typography>
        <Typography sx={classes.dialogRegularFont}>{t("hitDesc7")}</Typography>
      </Stack>
    );
  };

  return (
    <CustomDialog
      closable
      closeButtonVisibility
      borderRadius="10px"
      isDialogOpen={props.isOpen}
      handleDialogClose={handleClose}
      dialogHeaderContent={dialogHeaderContent()}
      dialogBodyContent={dialogBodyContent()}
    />
  );
};

export default FootballDialog;
