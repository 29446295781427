import type { ReactNode } from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { getCandidateIcon, getSettingsIcon } from "utils/DrawerIcons";
import strings from "global/constants/StringConstants";
import { store } from "utils/store";
import viewpaths from "global/constants/ViewPathConstants";

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  return [
    {
      items: [
        {
          title: strings.DASHBOARD,
          path: viewpaths.dashboardViewPath,
          icon: (
            <SvgIcon fontSize="small">
              {getCandidateIcon("var(--nav-item-icon-color)")}
            </SvgIcon>
          ),
          activeIcon: (
            <SvgIcon fontSize="small">
              {getCandidateIcon("var(--nav-item-active-color)")}
            </SvgIcon>
          ),
          accessToResource: [strings.app, strings.account, strings.document],
        },
        {
          title: strings.HOLIDAYCALENDER,
          path: viewpaths.holidayCalenderViewPath,
          icon: (
            <SvgIcon fontSize="small">
              {getSettingsIcon("var(--nav-item-icon-color)")}
            </SvgIcon>
          ),
          activeIcon: (
            <SvgIcon fontSize="small">
              {getSettingsIcon("var(--nav-item-active-color)")}
            </SvgIcon>
          ),
          accessToResource: [strings.app, strings.account],
        },
        {
          title: strings.SETTINGS,
          path: viewpaths.settingsViewPath,
          icon: (
            <SvgIcon fontSize="small">
              {getSettingsIcon("var(--nav-item-icon-color)")}
            </SvgIcon>
          ),
          activeIcon: (
            <SvgIcon fontSize="small">
              {getSettingsIcon("var(--nav-item-active-color)")}
            </SvgIcon>
          ),
          accessToResource: [strings.app, strings.account],
        },
      ],
    },
  ];
};

export const hasAccessToModule = (moduleAllowedResources: string[] = []) => {
  const userResources = store.getState().auth.resources;
  const hasAccess = moduleAllowedResources.some(
    (individualResource: any) =>
      userResources.hasOwnProperty(individualResource) &&
      (userResources[individualResource]?.includes(strings.fetchPermission) ||
        userResources[individualResource]?.includes(strings.adminPermission))
  );
  return hasAccess;
};

export const useHasAccess = (path: string) => {
  const sections = useSections();
  const section = sections[0].items.find((sec) => path.includes(sec.path));
  if (section) {
    return hasAccessToModule(section.accessToResource);
  }
  return false;
};

export const hasAccessTo = (resource: string, permission: string) => {
  const resources = store.getState().auth.resources;
  if (resources.hasOwnProperty(strings.app)) {
    return true;
  }
  if (
    resources.hasOwnProperty(resource) &&
    resources[resource].includes(strings.adminPermission)
  ) {
    return true;
  }
  if (!resources.hasOwnProperty(resource)) {
    return false;
  }
  return resources[resource].includes(permission);
};

export const useMakeUserAllowedSections = () => {
  const sections = useSections();
  const userSections = sections.map((section) => {
    const filteredSections = section.items.filter((item) =>
      hasAccessToModule(item.accessToResource)
    );
    return {
      ...section,
      items: filteredSections,
    };
  });
  return userSections;
};

export const useAuthRouteRedirection = () => {
  const routes = useMakeUserAllowedSections();
  if (routes[0].items.length > 0) {
    return routes[0].items[0].path;
  }
  return viewpaths.pageNotFoundViewPath;
};
