import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

const fallbackLng = ["pl"];
const availableLanguages = ["pl", "en", "fr", "de", "es", "hi", "zh"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "pl",
    fallbackLng,
    detection: {
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      checkWhitelist: true,
    },
    debug: true,
    supportedLngs: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
